
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
import '../Common.css'; 
import { Profile, vipList } from '../../Services/User';  
import useToken from '../App/useToken';
 
import { useTranslation } from 'react-i18next'; 
import { Divider } from 'react-vant';

import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

import { Tabs , Popup, Cell } from 'react-vant'
   
export default function TaskAILevel() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [ vip, setVip ] = useState(0);  
    const [ lists, setLists] = useState([]);
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }   
        getVip();  
        let vipLevel = localStorage.getItem('vip');  
        setVip(vipLevel)
        // eslint-disable-next-line
    }, []); 
 
    const goto = (path) => { 
        navigate('/' + path);
    }; 
   
    const getVip = async e => { 
        let token = localStorage.getItem('token');  
        vipList({ 
            token
        }, CallbackGetVip);  
    } 
    
    const CallbackGetVip = (returnData) => {  
        if(returnData.code === 1)
        { 
            var data = returnData.data; 
            setLists(data.data);  
        }  
    } 
     
    return (
    <> 
        <div className="task-center">
            <div className="module">
                {lists.map((vo, index) => ( 
                    <div key={index} className="box-card mt-3">
                        <div className="level-heading">
                            <div className="level-heading-col">
                                <span className="level-icon"><img src={`images/vip${vo.level}.png`} height={30}/></span>
                                <span className="level-title">{t("AI Level")} {vo.level}</span>
                            </div> 
                            <div className="level-heading-col">
                                <img src={`images/icon_reddit.png`} height={22}/>
                            </div> 
                        </div> 
                        <div className="level-summary">
                            <Row>
                                <Col>
                                    <div className="level-summary-item">
                                        <div className="level-summary-item-title">
                                            <img src={"images/icon_clpboard.png"} height={20} />
                                            <span>{vo.task_count}</span>
                                        </div>
                                        <h5>{t("Available Tasks")}</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="level-summary-item">
                                        <div className="level-summary-item-title">
                                            <img src={"images/icon_clpboard.png"} height={20} />
                                            <span>${vo.min_task_amount} to ${vo.max_task_amount}</span>
                                        </div>
                                        <h5>{t("Commission/task")}</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="level-summary-item">
                                        <div className="level-summary-item-title">
                                            <img src={"images/icon_clpboard.png"} height={20} />
                                            <span>{vo.commission_rate}%</span>
                                        </div>
                                        <h5>{t("Commission")}</h5>
                                    </div>
                                </Col>
                            </Row>
                        </div> 
                        <div className="level-action"> 
                     
                            {vo.diff > 0 ? (
                                <div className="level-action-flex">
                                    <h4>${vo.diff} {t("more to Upgrade")}</h4>
                                    <Button  size="sm" color="info" onClick={() => goto("deposit")}>{t("Upgrade")}</Button>
                                </div> 
                            ) : ( 
                                <div className="level-action-right">
                                    {vo.is_own === true ? ( 
                                        <Button  size="sm" color="primary" onClick={() => goto('tasks')}>{t("Enter")}</Button>
                                    ) : (
                                        <Button  size="sm" color="secondary" disabled>{t("Not Eligible")}</Button> 
                                    )}
                                </div> 
                            ) }
                        </div>   
                    </div>   
                ))}
            </div> 
        </div>  
    </>
    );
};