
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 

import { ReactComponent as IconNotif } from '../../assets/images/icon_bell.svg'; 
 

import AppCard from './AppCard';  
 
import '../Common.css'; 
import { RotOrder, levelList, taskLists } from '../../Services/User';  
import useToken from '../App/useToken';
 
import { useTranslation } from 'react-i18next';  
import { 
    Button,
    Row ,
    Col,
    Progress,
    Alert ,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

import { Tabs , Popup, Cell, List } from 'react-vant'
   
export default function TaskDetail() {  
 
    const userAgent = navigator.userAgent; 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [ vip, setVip ] = useState(0);  
    const [ progressVal, setProgressVal ] = useState(0);  
    const [ lists, setLists] = useState([]);
    const [ username, setUsername ] = useState(""); 
    const [ avatar, setAvatar ] = useState("");  
    
    const [ palProgress, setPalProgress ] = useState(0);  
    const [ currentProgress, setCurrentProgress ] = useState(0);  
    const [ totalProgress, setTotalProgress ] = useState(0);  
 
    const [ tab, setTab] = useState("completed"); 
    const [ balance, setBalance] = useState(0);
    const [ progressTotal, setProgressTotal ] = useState(0); 
    const [ progressBal, setProgressBal ] = useState(0);  
    const [ applists, setApplists] = useState([]); 
    const [ finished, setFinished] = useState(false)
    const [ currentPage, setCurrentPage] = useState(0); 
    const [ lastPage, setLastPage] = useState(0); 
    const [ lastTab, setLastTab] = useState('completed'); 
    const [ isLoading, setIsLoading] = useState(false)
    const [ canDo, setCanDo] = useState(1)
    const [ remainingTask, setRemainingTask] = useState(0)
    const [ commission, setCommission] = useState(0)
    const [ today_pending_task_count, setToday_pending_task_count] = useState(0)
    const [ trade_message, setTrade_message] = useState(null)
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }   
        StartRotOrder();  
        getVip();  
        let vipLevel = localStorage.getItem('vip');  
        setVip(vipLevel)
        // eslint-disable-next-line
    }, []); 
 
    const StartRotOrder = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            RotOrder({
                token
            }, CallbackRotOrder);  
        }
    } 
    
    const CallbackRotOrder = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setUsername(data.username); 
            setVip(data.level);
            setAvatar(data.avatar);
            setBalance(data.balance);

            let levelProgress = data.today_task_count / data.task_count * 100;
            setProgressVal(levelProgress.toFixed(2))
            setPalProgress(100-levelProgress.toFixed(2))

            setCurrentProgress(data.today_task_count)
            setTotalProgress(data.task_count)
            setRemainingTask(data.remaining_task)
            setCommission(data.today_reward)
            setToday_pending_task_count(data.today_pending_task_count)
            setTrade_message(data.trade_message)
        } 
    } 
  
    const goto = (path) => { 
        navigate('/' + path);
    }; 
   
    const getVip = async e => { 
        let token = localStorage.getItem('token');  
        levelList({ 
            token
        }, CallbackGetVip);  
    } 
    
    const CallbackGetVip = (returnData) => {  
        if(returnData.code === 1)
        { 
            var data = returnData.data; 
            setLists(data.lists);  
        }  
    } 
     
    useEffect(() => {   
        GetTaskLists();   
        // eslint-disable-next-line
    }, []); 
 
    const isAndroid = () => {
        return /Android/i.test(userAgent);
    };

    const isIOS = () => {
        return /iPhone|iPad|iPod/i.test(userAgent);
    };
 
    const GetTaskLists = async e => {    
        if(isLoading){ 
            return
        } 
        if(currentPage > 0 && currentPage === lastPage){
            return
        }
        let page = currentPage + 1;
        if(lastTab !== tab){    
            page = 1;
            setApplists([]);
        }  
        let device;
        if(isIOS()){
            device = "ios";
        } 
        else{
            device = "android";
        }
        
        setLastTab(tab)
        setIsLoading(true);
 
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            let type = "remaining"
            taskLists({
                token,
                type,
                device,
                page
            }, CallbackGetTaskLists);  
        }
    } 
    
    const CallbackGetTaskLists = (returnData) => {  
        setIsLoading(false);
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            appendData(data.data); 
            setCurrentPage(data.current_page) 
            setLastPage(data.last_page) 
                
            if(data.current_page === data.last_page){
                setFinished(true)
            } 
        } 
    }  
 
    const appendData = (newData) => {
        setApplists((prevData) => [...prevData, ...newData]);
    };
 
    return (
    <> 
        <div className="page-wrapper task-wrapper">   
            <div className="module"> 
                <div className="box-account"> 
                    <div className="box-account-info"> 
                        <span className="avatar">
                            <img src={avatar} height={48}/>
                        </span>
                        <div className="box-account-info-title"> 
                            <h4>{username}</h4>
                            <div className="account-level-info">
                                <h5>{t("Level")}.{vip}</h5>
                                <img src={`images/vip${vip}.png`} height={15}/>
                            </div>
                        </div>
                    </div>
                    <div className="box-account-info"> 
                        <span className="btn-notif"> 
                            <IconNotif/>
                        </span>
                    </div>
                </div>
            </div>   
            
            <div className="module mt-5"> 
                <h4>{t("My Level")}</h4> 
                <div className="box-card mt-3 my-level">
                    <h4>{t("Level")}</h4> 
                    <div className="level-lists-icons"> 
                        {lists.map((vo, index) => (
                            <span key={index} className={`level-icon`}>
                                <img src={`images/vip${vo.level}.png`} height={30}/>
                            </span>
                        ))}
                    </div> 
                    <div className="account-level-progress">
                        <Progress 
                            value={progressVal}
                            color={"warning"}
                            style={{
                                height: '5px'
                            }}
                        />
                    </div>
                </div> 
            </div> 
            
            {/* <div className="module mt-5"> 
                <h4>{t("Progress To Next Level")}</h4> 
                <div className="box-card mt-3">
                    <h4>{t("Progress")}</h4>  
                    <div className="account-level-progress-info">
                        <span className="progress-text">{palProgress}{t("% to complete")}</span>
                        <span className="progress-summary">{currentProgress}/{totalProgress}</span>
                    </div>
                    <div className="account-level-progress">
                        <Progress 
                            value={progressVal}
                            color={"warning"}
                            style={{
                                height: '12px'
                            }}
                        />
                    </div>
                </div> 
            </div>  */}

            <div className="module mt-5"> 
                <h4>{t("Your Tasks")}</h4> 
                <h5>{t("Complete Below Task To Level Up And Get Rewards")}</h5> 
                <div className="box-card mt-3">
                    <h4>{t("Progress")}</h4>  
                    <div className="account-level-progress-info">
                        <span className="progress-text">{palProgress}{t("% to complete")}</span>
                        <span className="progress-summary">{currentProgress}/{totalProgress}</span>
                    </div>
                    <div className="account-level-progress">
                        <Progress 
                            value={progressVal}
                            color={"warning"}
                            style={{
                                height: '12px'
                            }}
                        />
                    </div>
                    
                
                    <div className="order-list-items mt-3">  
                        <div className="order-list-item-title">{t('Balance')}</div>
                        <div className="order-list-item-value">{balance}</div>
                    </div>
                    <div className="order-list-items"> 
                        <div className="order-list-item-title">{t('Commission today')}</div>
                        <div className="order-list-item-value">{commission}</div>
                    </div>
                    <div className="order-list-items"> 
                        <div className="order-list-item-title">{t('Today Task Count(All)')}</div>
                        <div className="order-list-item-value">{currentProgress}</div>
                    </div>
                    <div className="order-list-items"> 
                        <div className="order-list-item-title">{t('Today Task Count(Pending)')}</div>
                        <div className="order-list-item-value">{today_pending_task_count}</div>
                    </div>
                    <div className="order-list-items"> 
                        <div className="order-list-item-title">{t('Remaining Task')}</div>
                        <div className="order-list-item-value">{remainingTask}</div>
                    </div>
                </div> 
            </div> 
          
            <div className="module mt-5"> 
                <h4>{t("Task Lists")}</h4> 
                <h5>{t("Start task today")}</h5> 
                    
                <div className="task-lists"> 
                {applists.length > 0 ? (
                    <List finished={finished} onLoad={GetTaskLists} offset={0} loadingText={t("loading...")}>
                        {applists.map(item => (
                            <AppCard item={item} type={"task"} key={item.id} toUpdate={StartRotOrder} canDo={remainingTask>0?1:0}/>
                        ))}
                    </List>
                ) : (
                    <div className="empty">{t("no task available")}</div>
                )}
                </div> 
            </div>
            {trade_message && (
                <Alert className="alert-bottom" color="danger">
                {trade_message}
                </Alert>
            )}
        </div>
    </>
    );
};