
import React, { useEffect, useState, useRef  } from 'react';

import { useNavigate } from 'react-router-dom'    
import { Profile, levelList } from '../../Services/User';  
import InnerNav from '../InnerNav';   
 
import { useTranslation } from 'react-i18next';

import { 
    Button,
    Progress ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

export default function Level() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();   
    const [ supportUrl, setSupportUrl] = useState('');
    const [ username, setUsername ] = useState(""); 
    const [ balance, setBalance ] = useState(""); 
    const [ invitecode, setInvitecode ] = useState(""); 
    const [ vip, setVip ] = useState(0); 
    const [ avatar, setAvatar ] = useState("");  
    const [ progressTotal, setProgressTotal ] = useState("00"); 
    const [ progressBal, setProgressBal ] = useState("00"); 
    const [ toggleBalance, setToggleBalance] = useState(true); 
    const [ avatarSrc, setAvatarSrc] = useState(""); 
    const [ progressVal, setProgressVal] = useState(0); 
    const [ lists, setLists] = useState([]); 
    
    const [ downloadTotal, setDownloadTotal] = useState(0); 
    const [ shareTotal, setShareTotal] = useState(0); 
    const [ surveyTotal, setSurveyTotal] = useState(0); 
    const [ commissionTotal, setCommissionTotal] = useState(0); 
    
    const [ downloadPal, setDownloadPal] = useState(0); 
    const [ sharePal, setSharePal] = useState(0); 
    const [ surveyPal, setSurveyPal] = useState(0); 
    const [ commissionPal, setCommissionPal] = useState(0); 
    
    const [ downloadUser, setDownloadUser] = useState(0); 
    const [ shareUser, setShareUser] = useState(0); 
    const [ surveyUser, setSurveyUser] = useState(0); 
    const [ commissionUser, setCommissionUser] = useState(0); 
    
    const [ download, setDownload] = useState(0); 
    const [ share, setShare] = useState(0); 
    const [ survey, setSurvey] = useState(0); 
    const [ commission, setCommission] = useState(0); 
    
    const [ currentLevel, setCurrentLevel] = useState(0); 
    const [ currentIndex, setCurrentIndex] = useState(0); 
     
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        getVip();  
        UserProfile();  
        // eslint-disable-next-line
    }, []); 

    useEffect(() => {  
        let download_pal = 0;
        let share_pal = 0;
        let survey_pal = 0;
        let commission_pal = 0;
   
        if(vip === currentLevel){ 
            download_pal = downloadUser / downloadTotal * 100;
            share_pal = shareUser / shareTotal * 100;
            survey_pal = surveyUser / surveyTotal * 100;
            commission_pal = commissionUser / commissionTotal * 100;
                
            setDownload(downloadUser)
            setShare(shareUser)
            setSurvey(surveyUser)
            setCommission(commissionUser)
        } else {
            setDownload(0)
            setShare(0)
            setSurvey(0)
            setCommission(0)
        }
        setDownloadPal(download_pal)
        setSharePal(share_pal)
        setSurveyPal(survey_pal)
        setCommissionPal(commission_pal)
        
        updateContent(currentIndex)
 
        // eslint-disable-next-line
    }, [ vip, currentLevel, currentIndex, downloadTotal, shareTotal, surveyTotal, commissionTotal]); 
  
    const UserProfile = async e => {    
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setUsername(data.username);
            setBalance(data.balance);
            setInvitecode(data.invite_code);
            setVip(data.vip); 
            setAvatar(data.avatar);  
            setCurrentLevel(data.vip);
            setProgressBal(data.progress_bal);
            setProgressTotal(data.progress_total);
            setProgressVal(data.progress_val);
        } 
    } 
    
    const getVip = async e => { 
        let token = localStorage.getItem('token');  
        levelList({ 
            token
        }, CallbackGetVip);  
    } 
    
    const CallbackGetVip = (returnData) => {  
        if(returnData.code === 1)
        { 
            var data = returnData.data; 
            setLists(data.lists); 
            updateContent(0);
 
            setDownloadUser(data.info.download)
            setShareUser(data.info.share)
            setSurveyUser(data.info.survey)
            setCommissionUser(data.info.commission)
    
        }  
    } 
    
    const updateContent = (index) => { 
        let datas = lists[index]
        setCurrentIndex(index);

        if(datas){
            setCurrentLevel(datas.level);
            setDownloadTotal(datas.task_count)
            setShareTotal(datas.share_count)
            setSurveyTotal(datas.survey_count)
            setCommissionTotal(datas.commission_count)
        }
    }; 

    return (
        <>
            <div className="page-wrapper level-wrapper">   

                <InnerNav title={t("Level System")} path="my" />

                <div className="box-card"> 
                    <div className="account-header">
                        <span className="avatar">
                            <img src={avatar} height={120}/>
                        </span>
                        <div className="account-title">
                            <h4>{username}</h4> 
                            <h5>{invitecode}</h5> 
                        </div>
                    </div>
                </div> 

                <div className="box-card"> 
                    <div className="account-level">
                        <div className="account-level-header">
                            <h5>{t("Level")} {vip}</h5>
                            <p>{t("500 Points to next level")}</p>
                        </div>
                        <div className="account-level-info"> 
                            <img src={`images/vip${vip}.png`} height={48}/>
                        </div>
                    </div>
                    <div className="account-level-bar">
                        <div className="account-level-progress">
                            <Progress value={progressVal} />
                        </div>
                        <div className="account-level-progress-text">
                            <span>{progressBal}/<span className="text-bold">{progressTotal}</span></span>
                        </div>
                    </div> 
                </div> 

                <div className="box-card"> 
                    <div className="level-lists-header"> 
                        <h4>{t("Select Level")}</h4>
                        <div className="level-lists-icons"> 
                            {lists.map((vo, index) => (
                                <span key={index} className={`level-icon ${currentIndex == index?'active':''}`} onClick={() => updateContent(index)}>
                                    <img src={`images/vip${vo.level}.png`} height={30}/>
                                </span>
                            ))}
                        </div> 
                    </div> 
                    <div className="level-lists-content">  
                        <div className="task-progress">
                            <h5>{t("Download ")}{downloadTotal} {t("Apps")}</h5>
                            <div className="account-level-bar">
                                <div className="account-level-progress">
                                    <Progress color="warning" value={downloadPal} />
                                </div>
                                <div className="account-level-progress-text">
                                    <span>{download}/<span className="text-bold">{downloadTotal}</span></span>
                                </div>
                            </div> 
                        </div>
                    </div> 
                    <div className="level-lists-content">  
                        <div className="task-progress">
                            <h5>{t("Share With")} {shareTotal} {t("Friends")}</h5>
                            <div className="account-level-bar">
                                <div className="account-level-progress">
                                    <Progress color="warning" value={sharePal} />
                                </div>
                                <div className="account-level-progress-text">
                                    <span>{share}/<span className="text-bold">{shareTotal}</span></span>
                                </div>
                            </div> 
                        </div>
                    </div> 
                    <div className="level-lists-content">  
                        <div className="task-progress">
                            <h5>{t("Survey")}</h5>
                            <div className="account-level-bar">
                                <div className="account-level-progress">
                                    <Progress color="warning" value={surveyPal} />
                                </div>
                                <div className="account-level-progress-text">
                                    <span>{survey}/<span className="text-bold">{surveyTotal}</span></span>
                                </div>
                            </div> 
                        </div>
                    </div> 
                    <div className="level-lists-content">  
                        <div className="task-progress">
                            <h5>{t("Earbed Commission")}</h5>
                            <div className="account-level-bar">
                                <div className="account-level-progress">
                                    <Progress color="warning" value={commissionPal} />
                                </div>
                                <div className="account-level-progress-text">
                                    <span>{commission}/<span className="text-bold">{commissionTotal}</span></span>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>  
            </div> 
        </>
    );
};