import React from 'react'; 
import Button from '@mui/material/Button';
import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 

function LogComponent({ item }) {
 
  const { t } = useTranslation();
  return (
    <div>
        <div className="records_item"  key={item.id}>
          {item !== null ? ( 
            <div className="records_tabs">
              <div className="transaction-row">
                <div className="transaction-info">
                  <p className="transaction-date">{item.datetime}</p>
                  <h3 className={`type_${item.type}`}>{item.typeName}</h3>
                </div>
                <div className="transaction-value">
                  <span className="badge text-bg-danger">{item.amount}</span>
                </div>
              </div>
            </div>
          ):''} 
        </div>
    </div>
  );
} 
export default LogComponent;
