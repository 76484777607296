
import React, {useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next'; 
import { useNavigate } from 'react-router-dom'     

import { Profile } from '../../Services/User';  
import InnerNav from '../InnerNav';   
  
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Security() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ tel, setTel ] = useState("");   
    const [ realname, setRealname ] = useState("");  
    const [ lang, setLang ] = useState("");  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
        // eslint-disable-next-line
    }, []); 
 
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setTel(data.tel);  
            setRealname(data.real_name); 
            setLang(data.lang); 
            
        } 
    } 
  
    const goto = (path) => { 
        navigate('/' + path);
    }; 
 
    return (
        <>
            <div className="page-wrapper account-wrapper">  

                <InnerNav title={t('Security Settings')} path="my"/>
 
                <div className="module">
                    <div className="card-header">
                        <h4>{t("Account Security")}</h4> 
                    </div>  
                </div> 

                <div className="account-lists"> 
                    <div className="account_nav"> 
                        <ul>
                            <li>
                                <span className="nav-link"  onClick={() => goto("password")}>
                                    <span className="nav-title"> 
                                        <span>{t("Account Password")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <span>{t("Edit")}</span>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={() => goto("withdraw-password")}>
                                    <span className="nav-title"> 
                                        <span>{t("Withdrawal Password")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <span>{t("Edit")}</span>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={() => alert("coming soon")}>
                                    <span className="nav-title"> 
                                        <span>{t("Two Factor Authentication")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <span>{t("Edit")}</span>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={() => alert("coming soon")}>
                                    <span className="nav-title"> 
                                        <span>{t("Add Recovery Email")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <span>{t("Edit")}</span>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div> 
                </div>

                <div className="module">
                    <div className="card-header">
                        <h4>{t("Payment Method")}</h4> 
                    </div>  
                </div> 
                <div className="account-lists"> 
                    <div className="account_nav"> 
                        <ul>
                            <li>
                                <span className="nav-link"  onClick={() => goto("bank")}>
                                    <span className="nav-title"> 
                                        <span>{t("Manage Bank")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <span>{t("Edit")}</span>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li> 
                        </ul>
                    </div> 
                </div> 
            </div> 
        </>
    );
};