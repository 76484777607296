
import React, {useEffect,useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom'    
 
import { GetDeposit, PostDeposit, Profile } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner';  
import { useTranslation } from 'react-i18next'; 
import InnerNav from '../InnerNav';   

import { 
    Button, 
    Row, 
    Col, 
    Input, 
    Label,
    FormGroup, 
} from "reactstrap";
   
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function RechargeForm() {  
 
    const { t } = useTranslation();
    const { type } = useParams();
    const navigate = useNavigate();  
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        GetRechargeInfo();   
    }, []); 

    const GetRechargeInfo = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            //let pay_name = type; 
            let pay_name = "trc20"; 
            GetDeposit({
                token,
                pay_name
            }, CallbackRechargeInfo);  
        }
    } 
    
    const CallbackRechargeInfo = (returnData) => {  
        if(returnData.code == 1)
        {  
            var data = returnData.data;
            setWallettype(data.wallet_type);   
            setWalletaddress(data.wallet_address);  
        }  
    } 
     
    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
      
    const [wallettype, setWallettype] = useState('');   
    const [walletaddress, setWalletaddress] = useState('');   
    const [amount, setAmount] = useState('');   
    const [hashcode, setHashcode] = useState('');    
  
    const handleDeposit = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');  
        // let pay_name = type; 
        let pay_name = "trc20"; 
        PostDeposit({ 
            token,
            amount,
            hashcode,
            pay_name
        }, CallbackDeposit);
    } 
 
    const CallbackDeposit = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data;
            notyf.success(t(returnData.message));  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 

    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value) 
    } 
    const amountLists = [
        { value: 10, label: '$10' },
        { value: 15, label: '$15' },
        { value: 20, label: '$20' },
        { value: 60, label: '$60' },
        { value: 100, label: '$100' },
    ];
  
      
    useEffect(() => {
        setIsDisabled(amount === '' || hashcode === '');  
    }, [amount, hashcode]);

    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper account-wrapper">  

                <InnerNav title={t("Recharge")} />

                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleDeposit} >   
                    
                        <FormGroup className="mb-3 disabled">
                            <label>{t("Wallet Type")}</label>
                            <Input 
                                placeholder={t("Enter Your Email Address")}   
                                value={wallettype} 
                                disabled
                            />
                        </FormGroup>
                        <FormGroup className="mb-3 disabled">
                            <label>{t("Wallet Address")}</label>
                            <Input 
                                placeholder={t("Wallet Address")}   
                                value={walletaddress} 
                                disabled
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <label>{t("Enter amount")}</label>
                            <Input 
                                placeholder={t("$5-$99999")}    
                                type="number" 
                                value={amount}
                                onChange={(e)=>inputChangeHandler(setAmount, e)}
                                required 
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <label>{t("Frequent recharge amounts")}</label>
                            <div className="list-amount">
                            {amountLists.map((vo) => ( 
                                <span
                                    key={vo.value}
                                    className="item-amount" 
                                    onClick={() => setAmount(vo.value)}
                                >
                                    {vo.label}
                                </span>  
                            ))} 
                            </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <label>{t("Hashcode")}</label>
                            <Input 
                                placeholder={t("Hashcode")}     
                                onChange={(e)=>inputChangeHandler(setHashcode, e)}
                                type="textarea"
                                required 
                            />
                        </FormGroup>
                   
                        <div className="form-action btn-block mt-5">   
                            <Button type="submit" disabled={isDisabled} color="primary">{t('Recharge')}</Button> 
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};