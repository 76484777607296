import React, {useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";    
import { Popup, Cell } from 'react-vant'
import LoadingSpinner from '../LoadingSpinner'; 

import { UserRegister, Profile, resendCode, verifyCode } from '../../Services/User'
import { Notyf } from "notyf";  
import { useTranslation } from 'react-i18next'; 
 
import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";
  
const notyf = new Notyf({duration: 5000}); 
    
export default function Verify() {    
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);   
    const [email, setEmail] = useState('');    
    const [isDisabledLink, setIsDisabledLink] = useState(false);   
    const [isSuccess, setIsSuccess] = useState(false);   
    const inputs = [useRef(null), useRef(null), useRef(null), useRef(null)];
 

    const [inputValues, setInputValues] = useState(['', '', '', '']);
        
    const navigate = useNavigate();

    useEffect(() => { 
        // if(token){ 
        //     navigate('/home');
        // }   
        UserProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
      
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setEmail(data.email);  
        } 
    } 

    const handleResend = async e => { 
        e.preventDefault();    
        setLoading(true); 
        let token = localStorage.getItem('token');  
        resendCode({
            token
        }, CallbackResend);
    } 
 
    const CallbackResend = (returnData) => {    
        setLoading(false);
        var code = returnData.code; 
        if(code === 1)
        {  
            setIsDisabledLink(true);
            notyf.success(returnData.data.result); 
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }

    const handleVerify = async e => { 
        e.preventDefault();  
        let code = inputValues.join('');  
 
        setLoading(true); 
        let token = localStorage.getItem('token');   
        
        verifyCode({
            token,
            code
        }, CallbackVerify);
    } 
 
    const CallbackVerify = (returnData) => {    
        setLoading(false);
        var code = returnData.code; 
        if(code === 1)
        { 
            setIsSuccess(true);
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }
  
    const handleInputChange = (index, value) => {
        // Allow only digits (0-9)
        if (/^[0-9]*$/.test(value) || value === '') {
          // Update the input values in state
          const newInputValues = [...inputValues];
          newInputValues[index] = value;
          setInputValues(newInputValues);
    
          // Move focus to the next input if there is a value
          if (value !== '' && index < inputs.length - 1) {
            inputs[index + 1]?.current?.focus();      
            inputs[index + 1].current.select();
          }
    
          // Enable/disable the submit button based on input values
          const isAnyInputEmpty = newInputValues.some((val) => val === ''); 

          if(isAnyInputEmpty){
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          } 
          
        }
    };

    const handleKeyDown = (index, e) => {
        // Move focus to the previous input on backtab or delete
        if ((e.key === 'Backspace' || e.key === 'Delete') && index > 0 && inputs[index - 1].current.value === '') {
          inputs[index - 1]?.current?.focus();    
          inputs[index - 1].current.select();
        }
        
        inputs[index].current.select();

        // Enable/disable the submit button based on input values
        const isAnyInputEmpty = inputValues.some((val) => val === '');
        if(isAnyInputEmpty){
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        } 
    };
 
    const goto = (path) => { 
        navigate('/' + path);
    }; 
   
    return( 
        <div className="page-wrapper verification-page"> 
            {loading ? <LoadingSpinner /> : ''} 
             
            <div className="inner-top-nav">
                <span className="btn-back" onClick={() => goto("login")}><i className="fa-solid fa-arrow-left"></i></span> 
                <span className="nav-title">{t("Verification")}</span>
                <span></span>
            </div>
 
            <div className="form-info">     
                <div className="form-heading">   
                    <img src={"/images/icon_email_verify.png"} height={110} alt={t("Email Verification")}/>

                    <h1>{t("Verification Code Sent")}</h1>
                    <h4>{t("We have sent verification code to")}</h4>
                    <h5>{email}</h5>
                </div>
                <div className="form-code">
                    {inputs.map((inputRef, index) => (
                        <input
                        key={index}
                        ref={inputRef}
                        type="text"
                        maxLength="1"
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        />
                    ))} 
                </div>    
                <div className="form-action btn-block pt-3">  
                    <Button onClick={handleVerify} disabled={isDisabled} color="primary" >{t('Submit')}</Button> 
                </div>      
 
                <div className="nav-reg">  
                    {t("Didn't receive code?")} <span className={`nav-link ${isDisabledLink ? 'disable' : ''}`} onClick={isDisabledLink ? null : handleResend}>{t("Resend")}</span> 
                </div>
  
            </div> 
 
            <Popup
                visible={isSuccess} 
                style={{ height: '70%' }}
                position='bottom'
                round 
            >
                <div className="form-popup">   
                    <img src={"/images/icon_success.png"} height={110} alt={t("Email Verification Success")}/>

                    <h1>{t("Registration Successful")}</h1>
                    <h4>{t("Congratulations! your account has created. Login now to experience the app ")}</h4>
                    <div className="form-action btn-block pt-3">  
                        <Button onClick={() => goto('home')} color="primary" >{t('Go To Homepage')}</Button> 
                    </div>      
                </div> 
            </Popup>
             
        </div> 
    );
} 