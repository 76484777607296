
import React, {useEffect,useState } from 'react';


import { useNavigate, useParams } from 'react-router-dom'    
  
import { Profile, taskHistory  } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';  
import TaskAILevel from './TaskAILevel';  
import TaskCompare from './TaskCompare';  
import { useTranslation } from 'react-i18next'; 
import { Divider } from 'react-vant';
 
import AppCard from './AppCard';  
import InnerNav from '../InnerNav';   
 
import { ReactComponent as IconWallet } from '../../assets/images/icon_wallet.svg'; 
import { ReactComponent as IconCommission } from '../../assets/images/icon_commission.svg'; 

import { 
    Button,
    Row ,
    Col,
    Progress,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

import { Tabs , Popup, Cell, List } from 'react-vant'
   
export default function TaskHistory() {  
 
    const { type } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken(); 
    const [ username, setUsername ] = useState(""); 
    const [ avatar, setAvatar ] = useState("");   
    const [ invitecode, setInvitecode ] = useState(""); 
    const [ vip, setVip ] = useState(0); 
    const [ showInvite, setShowInvite ] = useState(false); 
    const [ taskCount, setTaskCount ] = useState(0); 
    const [ tab, setTab] = useState(type || "completed"); 
    const [ commission, setCommission] = useState(0);
    const [ balance, setBalance] = useState(0);
    const [ progressTotal, setProgressTotal ] = useState("00"); 
    const [ progressBal, setProgressBal ] = useState("00"); 
    const [ progressVal, setProgressVal] = useState(0); 
    const [ applists, setApplists] = useState([]); 
    const [ finished, setFinished] = useState(false)
    const [ currentPage, setCurrentPage] = useState(0); 
    const [ lastPage, setLastPage] = useState(0); 
    const [ lastTab, setLastTab] = useState('completed'); 
    const [ isLoading, setIsLoading] = useState(false)
    const [ canDo, setCanDo] = useState(true)
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile()
        // eslint-disable-next-line
    }, []); 

    // useEffect(() => {  
    //     let tokenInfo = localStorage.getItem('token');   
    //     if(tokenInfo === null){ 
    //         navigate('/login');
    //     }    
    //     let is_new = localStorage.getItem('is_new');  
    //     setIsNew(is_new);
    //     let user_name = localStorage.getItem('username');  
    //     setUsername(user_name);
    //     let avatar_url = localStorage.getItem('avatar');  
    //     setAvatar(avatar_url);
    //     // eslint-disable-next-line
    // }, []); 
  
    useEffect(() => {  
        GetTaskLists() 
        // eslint-disable-next-line
    }, [tab]); 
  
    const UserProfile = async e => {    
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            
            setProgressVal(data.newtask_pal);
            setProgressBal(data.newtask_count);
            setProgressTotal(data.newtask_total); 
            setCommission(data.commission); 
            setBalance(data.balance); 

            if(progressBal == progressVal){
                setCanDo(false);
            }
        } 
    }  
  
    const GetTaskLists = async e => {    
        if(isLoading){ 
            return
        } 
        if(currentPage > 0 && currentPage === lastPage){
            return
        }
        let page = currentPage + 1;
        if(lastTab !== tab){    
            page = 1;
            setApplists([]);
        }  
        
        setLastTab(tab)
        setIsLoading(true);
 
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            let type = tab
            taskHistory({
                token,
                type,
                page
            }, CallbackGetTaskHistory);  
        }
    } 
    
    const CallbackGetTaskHistory = (returnData) => {  
        setIsLoading(false);
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            appendData(data.data); 
            setCurrentPage(data.current_page) 
            setLastPage(data.last_page) 
                
            if(data.current_page === data.last_page){
                setFinished(true)
            }
            
        } 
    }  

    const goto = (path) => { 
        navigate('/' + path);
    }; 

    const setCurentTab = (currentTab) => { 
        setFinished(false)
        setApplists([]);
        setCurrentPage(0) 
        setTab(currentTab)
    }
    const appendData = (newData) => {
        setApplists((prevData) => [...prevData, ...newData]);
    };

    return (
    <>   
       <div className="page-wrapper task-history">   
                    
            <InnerNav title={t("Task History")} path="my" />

            <div className="module"> 
                <Row>
                    <Col>
                        <div className="box-wallet">
                            <div className="box-wallet-icon">
                                <IconWallet/>
                            </div>
                            <div className="box-wallet-content">
                                <h5>{t("My Balance")}</h5>
                                <h4>${balance}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="box-wallet">
                            <div className="box-wallet-icon">
                                <IconCommission/>
                            </div>
                            <div className="box-wallet-content">
                                <h5>{t("Earned Commission")}</h5>
                                <h4>${commission}</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div> 
 
            <div className="task-center">
                <div className="module">
                    <div className="card-header mt-5"> 
                        <div className="task-history-action">
                            <span className={tab === 'completed'?'active':''} onClick={() => setCurentTab("completed")}>{t("Completed Task")}</span>
                            <span className={tab === 'pending'?'active':''} onClick={() => setCurentTab("pending")}>{t("Pending Task")}</span> 
                        </div> 
                    </div>  
                </div> 
            </div> 
            <div className="task-lists">
            {applists.length > 0 ? (
                <List finished={finished} onLoad={GetTaskLists} offset={0} loadingText={t("loading...")}>
                    {applists.map(item => (
                        <AppCard item={item} type={"task"} key={item.id} toUpdate={UserProfile} canDo={canDo}/>
                    ))}
                </List>
            ) : (
                <div className="empty">{t("no task available")}</div>
            )}
            </div>
            
        </div>
    </>
    );
};