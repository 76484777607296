
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
import '../Common.css'; 
import { Profile, singleApp, Banner, PageVersion, BannerVersion } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';  
import { useTranslation } from 'react-i18next'; 
import { Divider } from 'react-vant';
 
import AppCard from './AppCard';  

import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

import { Tabs , Popup, Cell } from 'react-vant'
   
export default function Home() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken(); 
    const [ username, setUsername ] = useState(""); 
    const [ balance, setBalance ] = useState(""); 
    const [ invitecode, setInvitecode ] = useState(""); 
    const [ vip, setVip ] = useState(0); 
    const [ avatar, setAvatar ] = useState("");  
    const [ showInvite, setShowInvite ] = useState(false); 
    const [ oneApps, setOneApps ] = useState([]); 
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }  
        
        UserProfile(); 
        GetSingleApps(); 
  
        // eslint-disable-next-line
    }, []); 
 
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setToken(data); 
          
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', data.token);  
            localStorage.setItem('userdata', JSON.stringify(data));   
            localStorage.setItem('lang', data.lang);  
            localStorage.setItem('vip', data.vip);  
            localStorage.setItem('is_new', data.is_new);  
            localStorage.setItem('avatar', data.avatar);  
            setUsername(data.username);
            setBalance(data.balance);
            setInvitecode(data.invite_code);
            setVip(data.vip);
            setAvatar(data.avatar);
            
            if(data.is_verify === 0){
                navigate('/verify');
            } else {
                navigate('/home');
            }
        } 
    } 
    
    const GetSingleApps = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            singleApp({
                token
            }, CallbackGetSingleApps);  
        }
    } 
    
    const CallbackGetSingleApps = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setOneApps(data);  
        } 
    } 
  
    const goto = (path) => { 
        navigate('/' + path);
    }; 
  
    const items = [
        { id: 1, title:t("Newbie Tasks")},
        { id: 2, title:t("AI Tasks")},
        { id: 3, title:t("AI Upgrade")}
    ]
 
    return (
    <>
        <div className="page-wrapper home-wrapper">   
            <div className="module"> 
                <div className="box-account"> 
                    <div className="box-account-info"> 
                        <span className="avatar">
                            <img src={avatar} height={48}/>
                        </span>
                        <div className="box-account-info-title"> 
                            <h5>{t("Welcome Back!👋")}</h5>
                            <h4>{username}</h4>
                        </div>
                    </div>
                    <div className="box-account-info"> 
                        <img src={`images/vip${vip}.png`} height={48}/>
                    </div>
                </div>
            </div>  

            <div className="module"> 
                <div className="box-intro"> 
                    <div className="box-intro-wrapper" style={{ backgroundImage: "url('images/WorkPlace.png')" }}> 
                        <div className="box-intro-info"> 
                            <h5>{t("AppBounty")}</h5>
                            <Button onClick={() => goto("shop")} color="primary" >{t('Get Started')}</Button>  
                            <p>{t("Your gateway to earning in the Middle East. Embrace the power of apps and start your earning journey today")}</p>
                        </div>
                    </div> 
                </div>
            </div> 

            <div className="module">
                <div className="card-header">
                    <h4>{t("Task Center")}</h4>
                    <span className="btn-more" onClick={() => goto("shop")}><i className="fa-solid fa-arrow-right"></i></span> 
                </div> 

                <div className='task-tabs'> 
                
                    <div className="task-action home mt-3"> 
                        {items.map(item => (
                        <span onClick={() => goto("task-center")} key={item.id}  className={item.id === 1?'active':''} > 
                                {item.title}
                        </span> 
                        ))}
                    </div> 
                    
                    {oneApps && (
                        <AppCard item={oneApps} type={"home"}/>
                    )}
             
                </div>
            </div> 

            <div className="module mt-5">
                <div className="card">
                    <div className="card-header">
                        {t("How its work")}
                    </div>
                    <div className="card-body">
                        <p>
                            {t("Dive into our marketplace and pick your first task. Whether it's a simple app download or a quick survey, your journey to earning starts here. Select, complete, and earn – it's as simple as that!")}
                        </p>
                    </div>
                </div>
            </div>
            
            <div className="module">
                <div className="card">
                    <div className="card-header">
                        {t("Personal page")}
                        <span className="btn-more" onClick={() => goto("page")}><i className="fa-solid fa-arrow-right"></i></span> 
                    </div>
                    <div className="card-body">
                        <div className="page-author">
                            <span className="avatar">
                                <img src={avatar} height={48}/>
                            </span>
                            <h4>{t("Andrew Ainsley")}</h4>
                        </div>
                        <p>
                            {t("Manage your tasks, check your balance, update your profile, and customize your settings. Take control and make AppBounty work for you")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
        <Popup 
            visible={showInvite} 
            closeable
            round 
            style={{ height: '90%', width: '80%', maxWidth:'450px' }}
            onClick={() => setShowInvite(false)}
        >
            <div className="invite-wrapper">
                <h4>{t("Invite Friends")}</h4>
                <p>{t("Copy your code share it with your freinds.")}</p>
                <img src={"images/icon_invite.png"}/>
                
                <FormGroup className="input-invite-row">
                    <label>{t("Your Code")}</label>
                    <div className="input-invite">
                        <Input 
                            placeholder={t("Enter Email")}   
                            value={invitecode}  
                            disabled
                            />
                        <Button className="btn-copy" color="primary">{t("Copy")}</Button>
                    </div>
                </FormGroup> 

                <div className="form-separate">  
                    <Divider>{t("Or")}</Divider>
                </div>

                <div className="social-media">
                    <span className="share-social-media"><img src={"images/logo_whatsapp.png"}/></span>
                    <span className="share-social-media"><img src={"images/logo_fb.png"}/></span>
                    <span className="share-social-media"><img src={"images/logo_ig.png"}/></span>
                    <span className="share-social-media"><img src={"images/logo_twitter.png"}/></span>
                </div>
            </div>
        </Popup>

        <Footer/>
    </>
    );
};