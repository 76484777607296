import * as React from 'react';
import { useNavigate, useLocation  } from "react-router-dom"; 
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconHome } from '../../assets/images/icon_home.svg';
import { ReactComponent as IconHomeActive } from '../../assets/images/icon_home_active.svg';
import { ReactComponent as IconTask } from '../../assets/images/icon_task.svg';
import { ReactComponent as IconTaskActive } from '../../assets/images/icon_task_active.svg';
import { ReactComponent as IconProfile } from '../../assets/images/icon_profile.svg';
import { ReactComponent as IconProfileActive } from '../../assets/images/icon_profile_active.svg';
import { ReactComponent as IconRecord } from '../../assets/images/icon_record.svg'; 
import { ReactComponent as IconRecordActive } from '../../assets/images/icon_record_active.svg'; 
 
export default function Footer(props) {
    const navigate = useNavigate();
    const { t }     = useTranslation();
    const location  = useLocation();
    const currentPath = location.pathname;
    const path = currentPath.substring(1);
 
    const goto = (path) => { 
        navigate('/' + path);
    }; 
    
    return (
        <div className="nav-footer"> 
            <div onClick={() => goto("")} className={`nav-footer-items ${path === 'home' ? 'active' : ''} `}>
                <span className="footer-icon">
                    {path === 'home' ? (
                        <IconHomeActive />
                    ) : (
                        <IconHome />
                    )}
                </span>
                <span>{t('Home')}</span>
            </div> 
            <div onClick={() => goto("task-center")} className={`nav-footer-items ${path === 'task-center' ? 'active' : ''} `}>
                <span className="footer-icon">
                    {path === 'task-center' ? (
                        <IconTaskActive />
                    ) : (
                        <IconTask />
                    )} 
                </span>
                <span>{t('Shop')}</span>
            </div>
            <div onClick={() => goto("task-history")} className={`nav-footer-items ${path === 'record-center' ? 'active' : ''} `}>
                <span className="footer-icon">
                    {path === 'record-center' ? (
                        <IconRecordActive />
                    ) : (
                        <IconRecord />
                    )}  
                </span>
                <span>{t('Record')}</span>
            </div>
            <div onClick={() => goto("my")}  className={`nav-footer-items ${path === 'my' ? 'active' : ''} `}>
                <span className="footer-icon"> 
                    {path === 'my' ? (
                        <IconProfileActive />
                    ) : (
                        <IconProfile />
                    )}  
                </span>
                <span>{t('My')}</span>
            </div> 
        </div> 
      );
} 