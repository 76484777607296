import React, {useEffect,useState } from 'react';

import { useTranslation } from 'react-i18next'; 
import { logs } from '../../Services/Utils';

import Slider from 'react-slick'; 

const VipCarousel = ({ currentSlide  }) => {

    const { t } = useTranslation();
    const [image, setImage] = useState("");   
    const [title, setTitle] = useState("");    
    const [content, setContent] = useState("");
 
    useEffect(() => {
      updateTitleAndContent(currentSlide);
    }, [currentSlide]);
    
    const imageData = [
      { id: 1, title: 'AppBounty Gateway', content: 'Your doorway to earnings in the Middle East. Harness the power of apps and start your rewarding journey with AppBounty today!'  },
      { id: 2, title: 'AppBounty Explorer', content: 'Discover the potential of earning with AppBounty in the Middle East. Embrace the app experience and kickstart your journey to rewards effortlessly.'},
      { id: 3, title: 'AppBounty Rewards',content:'Navigate the world of earnings with AppBounty. Unleash the power of apps and embark on your path to rewards in the Middle East today!' },
    ];

        
    const updateTitleAndContent = (index) => { 
        let datas = imageData[index];  
        if(datas){

          setImage(`images/slider/0${datas.id}.png`)  
          setTitle(datas.title)  
          setContent(datas.content)  
        } else { 
          logs("invalid index="+index)
        }
        
      logs("index="+index)
      logs(datas)
    };
   
      
  return ( 
    currentSlide >= 0 && ( 
      <div className="landing-wrapper">
        <div className="landing-slider"> 
          <img src={image} alt={title} height="500" /> 
        </div> 
          <div className="card-wrapper"> 
            <div className="mb-3"> 
              <h4>{title}</h4>
              <div className="slider-content">{content}</div>
            </div>  
          </div>
      </div> 
    )
  );
}

export default VipCarousel;
