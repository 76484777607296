
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom' 
import { GetBank, PostWithdraw, Profile } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner'; 
import { useTranslation } from 'react-i18next'; 
  
import { ReactComponent as IconBalance } from '../../assets/images/icon_balance.svg';   
import { ReactComponent as IconInfo } from '../../assets/images/icon_info.svg'; 
import { ReactComponent as IconEyeO } from '../../assets/images/icon_eye_o.svg'; 
import { ReactComponent as IconEyeC } from '../../assets/images/icon_eye_c.svg'; 

import InnerNav from '../InnerNav';   

import { 
    Button, 
    InputGroup, 
    InputGroupText, 
    Input, 
    Label,
    FormGroup, 
} from "reactstrap";
   
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 8000}); 

export default function Withdrawal() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    const [ amount, setAmount] = useState(false);   
    const [ password, setPassword] = useState('');   
    const [ wallettype, setWallettype] = useState('');     
    const [ walletaddress, setWalletaddress] = useState('');     
    const [ balance, setBalance ] = useState("");   

    const [ canUsdt, setCanUsdt] = useState(true);   
    const [ canBtc, setCanBtc] = useState(false);   
    const [ canVisa, setCanVisa] = useState(false);   
    const [ canMaster, setCanMaster] = useState(false);   
    const [ canPaypal, setCanPaypal] = useState(false);   
  
    const [toggleBalance, setToggleBalance] = useState(true); 
    const [ showPs, setShowPs] = useState(false); 
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
  
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []); 

    const handleGoBack = () => {
        window.history.back();  
    };
     
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setBalance(data.balance);
            if (data.pwd2 === null || data.pwd2 === "") { 
                notyf.error(t("Please set withdrawal password to continue"));   
                navigate('/withdraw-password'); 
            }  else {
                UserBank();  
            }
                
        } 
    } 

    const UserBank = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            GetBank({
                token
            }, CallbackGetBank);  
        }
    } 
    
    const CallbackGetBank = (returnData) => {  
        if(returnData.code === 1)
        { 
            var data = returnData.data;  
            console.log(data)
            if(data === null || data ===  undefined){
                notyf.error(t("Please bind bank to continue"));    
                navigate('/bank'); 
            } else {
                setWallettype(data.wallet_type);
                setWalletaddress(data.wallet_address);
            }
        }  
    } 

    const handleWithdrawal = async e => { 
        e.preventDefault();      

        if(parseFloat(amount) > parseFloat(balance)){ 
            notyf.error(t("Withdrawal amount cannot more than USDT ")+balance);
            return false;   
        }
        setLoading(true);  
        let token = localStorage.getItem('token');  
        PostWithdraw({ 
            token,
            amount,
            password
        }, CallbackWithdrawal);
    } 
 
    const CallbackWithdrawal = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        {  
            notyf.success(t(returnData.message));  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
    
    const withdrawAll = () => { 
        setAmount(balance)
    }

    const toggleShowPs = () => {
        setShowPs((showPs) => !showPs); 
    }; 

    useEffect(() => {
        setIsDisabled(amount === '' || password === ''); 
    }, [amount, password]);

    const cardStyle = { 
        backgroundImage: 'url(/images/balance_bg.png)',
        backgroundPosition: 'top center',    
        backgroundSize: 'contain',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat'
    }; 
 
    const getMaskedBalance = () => {
        const maskedBalance = balance.toString().replace(/\d/g, '*');
        return `******`;
      };
    
    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper withdraw-wrapper">  
                
                <InnerNav title={t("Withdraw")} path="my" />
 
                <div className="module account-balance">
                    <div className="card">
                        <div className="card-header">
                            <span>{t("Guarda Balance")} <IconInfo /></span>
                            <span className="btn-more"><IconBalance/></span> 
                        </div>
                        <div className="card-body">
                            <div className="balance-amount">
                                <span className="amount">$ {toggleBalance ? balance : getMaskedBalance()}</span>
                                { toggleBalance ? (
                                    <IconEyeC onClick={() => setToggleBalance(!toggleBalance)} height={18}/>
                                ) : (
                                    <IconEyeO onClick={() => setToggleBalance(!toggleBalance)} height={18}/>
                                )}
                            </div> 
                        </div>
                    </div>
                </div>

                <div className="form-wrapper">  
                     <form className="form" onSubmit={handleWithdrawal} >   
                            
                        <FormGroup className="mb-3"> 
                            <label>{t("Withdraw amount")}</label>
                            <Input 
                                placeholder={t("$5-$99999")}    
                                type="number" 
                                value={amount}
                                onChange={(e)=>inputChangeHandler(setAmount, e)}
                                required 
                            />
                        </FormGroup>
                        <FormGroup className="mb-3 disabled"> 
                            <label>{t("Wallet Type")}</label>
                            <Input     
                                value={wallettype} 
                                disabled 
                            />
                        </FormGroup>
                        <FormGroup className="mb-3 disabled"> 
                            <label>{t("Wallet Address")}</label>
                            <Input     
                                value={walletaddress} 
                                disabled 
                            />
                        </FormGroup> 
   
                        <FormGroup className="mb-3">
                            <label>{t("Password")}</label>
                            <InputGroup className="input-group-alternative">  
                            <Input 
                                placeholder={t("Withdrawal Password")}  
                                name="password"
                                type={showPs ? 'text' : 'password'}
                                value={password}
                                onChange={(e)=>inputChangeHandler(setPassword, e)}    
                            />
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                <IconEyeO/> 
                                ): (
                                <IconEyeC/> 
                            )}
                            </InputGroupText> 
                            </InputGroup>
                        </FormGroup> 
  
                        <div className="form-action btn-block mt-5">   
                            <Button type="submit" color="primary" disabled={isDisabled}>{t('Withdraw Now')}</Button> 
                        </div>   
                    </form> 
                </div> 
            </div> 
        </>
    );
};