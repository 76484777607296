import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";   
import TextField from '@mui/material/TextField'; 
import LoadingSpinner from '../LoadingSpinner'; 

import { UserRegister, countryCode} from '../../Services/User'
import useToken from '../App/useToken';   
import FormControl from '@mui/material/FormControl'; 

import { Notyf } from "notyf";  
import { useTranslation } from 'react-i18next'; 
import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";
  
 
import Carousel from './Carousel';     
 
const notyf = new Notyf({duration: 5000}); 
    
export default function Landing() {    
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    const { inviteCode } = useParams();
    const [invite_code, setInvite_code] = useState(inviteCode || '');
    const isReadOnly = inviteCode !== undefined; 
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [username, setUsername] = useState('');   
    const [tel, setTel] = useState('');    
    const [pwd, setPwd] = useState('');   
    const [pwdConfirm, setPwdConfirm] = useState('');    
    const [currentSlide, setCurrentSlide] = useState(0);    
    const [buttonText, setButtonText] = useState(t("Continue"));    
    const [hideSkip, setHideSkip] = useState(false);    
      
    const login = () => { 
        navigate('/login');
    }; 

    const { token, setToken } = useToken();
    const navigate = useNavigate();

    useEffect(() => { 
        if(token){ 
            navigate('/home');
        }  
        GetCountryCode(); 
        console.log("currentSlide="+currentSlide)
        if(currentSlide === 2){
            setButtonText(t("Get Started"))
            setHideSkip(true)
        } else { 
            setButtonText(t("Continue"))
            setHideSkip(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlide]);

    const GetCountryCode = async e => {    
        countryCode({}, CallbackCountryCode);  
    } 
    
    const CallbackCountryCode = (returnData) => {  
        if(returnData.code === 1)
        {  
            var data = returnData.data;  
            setAllowedCountries(data); 
            console.log(data)
        }  
    } 
      
    const handleRegister = async e => { 
        e.preventDefault(); 
 
        if(pwd !==  pwdConfirm)
        {
            notyf.error(t("Invalid password confirmation")); 
            return false;
        }
        e.preventDefault();      
        setLoading(true); 
        UserRegister({
            username,
            tel,
            pwd,
            pwdConfirm,
            invite_code
        }, Callback);
    } 
 
    const Callback = (returnData) => {    
        setLoading(false);
        var code = returnData.code; 
        if(code === 1)
        { 
            var token = returnData.data;
            setToken(token.token); 
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', token.token);  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }
 
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
       
    const handlePhoneChange = (newPhone) => {
        setTel(newPhone)
    }

    useEffect(() => {
        setIsDisabled(username === '' || tel === '' || pwd === '' || pwdConfirm === '' || invite_code === ''); 
    }, [username, pwd, tel, pwdConfirm, invite_code]);
    
    const goto = (path) => { 
        navigate('/' + path);
    }; 

    const nextSlide = (page) => { 
        if(page === -1){ 
            if(currentSlide !== 2){
                setCurrentSlide(currentSlide+1)
            } else {
                goto('login')
            }
        } else { 
            setCurrentSlide(page)
        }
    }; 
  
    return( 
        <div className="page-wrapper landing-page"> 
            {loading ? <LoadingSpinner /> : ''} 
             
            <div className="landing-wrapper">
                <Carousel currentSlide={currentSlide}/> 
                    
                <div className="stepper">
                    <span onClick={() => nextSlide(0)} className={`dot ${currentSlide==0?'active':''}`}></span>
                    <span onClick={() => nextSlide(1)} className={`dot ${currentSlide==1?'active':''}`}></span>
                    <span onClick={() => nextSlide(2)} className={`dot ${currentSlide==2?'active':''}`}></span>
                </div> 
            </div>

            <div className={`landing-action ${hideSkip ? 'btn-block' : ''}`}>
                {hideSkip === false && (
                    <Button type="submit" outline color="primary" onClick={() => goto('login')}>
                        {t('Skip')}
                    </Button>
                )}
                <Button type="submit" color="primary" onClick={() => nextSlide(-1)}>
                    {buttonText}
                </Button>
            </div>

        </div>
        
    );
} 