
import React, { useEffect, useState, useRef  } from 'react';

import { useNavigate } from 'react-router-dom'     
 
import LoadingSpinner from '../LoadingSpinner';   
import { ReactComponent as IconUpload } from '../../assets/images/icon_upload.svg'; 
import InnerNav from '../InnerNav';   
import { Profile, profileUpdate, updateAvatar } from '../../Services/User';  
  
import { useTranslation } from 'react-i18next'; 

import { 
    Button, 
    Row, 
    Col, 
    Input, 
    Label,
    FormGroup, 
} from "reactstrap";
   
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

 
export default function Account() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const formRef = useRef();
 
    const [ loading, setLoading ] = React.useState(false)  
    const [ firstname, setFirstname ] = useState("");  
    const [ lastname, setlastname ] = useState("");  
    const [ email, setEmail ] = useState("");  
    const [ dob, setDob ] = useState("");  
    const [ gender, setGender ] = useState("");  
    const [avatarSrc, setAvatarSrc] = useState(null); 
     
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();  
         
        // eslint-disable-next-line
    }, []); 
 

    const UserProfile = async e => {    
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setFirstname(data.firstname);  
            setlastname(data.lastname); 
            setEmail(data.email); 
            setDob(data.dob); 
            setGender(data.gender);
            if(data.avatar){
                localStorage.setItem('avatar', data.avatar);  
                setAvatarSrc(data.avatar); 
            } 
            
        } 
    } 
 
    const handleFileChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
          const reader = new FileReader();
          
          reader.onloadend = () => {
            setAvatarSrc((prevAvatarSrc) => {
              // Use the callback to make sure we get the updated state
              handleUpdateAvatar(reader.result); // Log the result here if needed
              return reader.result;
            }); 
          };
          reader.readAsDataURL(file);
        }
    };

    const handleUpdateAvatar = (avatarData) => {  
        let token = localStorage.getItem('token');    
        let avatar = avatarData;    
        
        updateAvatar({ 
            token,
            avatar
        }, CallbackHandleUpdateAvatar);
    } 

    const CallbackHandleUpdateAvatar = (returndata) => {
        console.log(returndata)
    }
  
    const handleUploadClick = () => {
        // Trigger the hidden file input
        document.getElementById('fileInput').click();
    };

    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value) 
    }     

    const handleFormSubmit = async e => { 
        e.preventDefault();      
        setLoading(true);   
    
        let token = localStorage.getItem('token');   
        profileUpdate({ 
            token,
            firstname,
            lastname,
            email,
            dob,
            gender
        }, CallbackFormSubmit);
    } 
 
    const CallbackFormSubmit = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var result = returnData.data;   
            notyf.success(result.result); 
            UserProfile();
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }
 
    return (
        <>
            <div className="page-wrapper account-wrapper">  
                {loading ? <LoadingSpinner /> : ''} 

                <InnerNav title={t("Personal Details")} path="my" />

                <div className="form-body"> 
                    <div className="form-row">
                        <span className="avatar">
                            {avatarSrc && <img src={`${avatarSrc}`} alt="Avatar" className="avatar-image" />}
                        </span>
                        <div className="avatar-upload">
                            <Button className="btn-upload" color="primary" onClick={handleUploadClick}>
                                <IconUpload /> <span>{t('Upload')}</span>
                            </Button>
                            <input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div> 
                </div> 
                
                <form className="form" onSubmit={handleFormSubmit} method="POST">   
                    <div className="form-body"> 
                        <FormGroup className="mb-3">
                            <label>{t("Name")}</label>
                            <Row>
                                <Col>  
                                    <Input 
                                        name="firstname"
                                        placeholder={t("First Name")}
                                        value={firstname}
                                        onChange={(e)=>inputChangeHandler(setFirstname, e)} 
                                        required   
                                    />
                                </Col>
                                <Col> 
                                    <Input 
                                        name="lastname"
                                        placeholder={t("Last Name")}
                                        value={lastname}
                                        onChange={(e)=>inputChangeHandler(setlastname, e)}  
                                        required  
                                    />
                                </Col>  
                            </Row> 
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <label>{t("Email")}</label>
                            <Input 
                                placeholder={t("Enter Your Email Address")}  
                                name="email" 
                                type="email" 
                                value={email}
                                onChange={(e)=>inputChangeHandler(setEmail, e)}    
                                required
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <label>{t("Date Of Birth")}</label>
                            <Input 
                                placeholder={t("Enter Your Email Address")}  
                                name="dob" 
                                type="date"  
                                value={dob}
                                onChange={(e)=>inputChangeHandler(setDob, e)}   
                                required 
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <label>{t("Gender")}</label>
                            <Input 
                                name="gender"
                                value={gender}
                                onChange={(e)=>inputChangeHandler(setGender, e)}    
                                type="select"
                                required
                            >
                                <option value={"male"}>{t("Male")}</option>
                                <option value={"female"}>{t("Female")}</option> 
                            </Input>
                        </FormGroup>
                    </div>   
                    <div className="form-action btn-block mt-5">
                        <Button color="primary" type="submit">{t("Save")}</Button>
                    </div>   
                </form>
            </div> 
        </>
    );
};