
import React, {useEffect,useState} from 'react'; 
import { useNavigate } from 'react-router-dom'    
import { useTranslation } from 'react-i18next'; 
import { Collapse } from 'react-vant';

import { faq, faqVersion, GetSupport } from '../../Services/User';  
import InnerNav from '../InnerNav';   

export default function QnA() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ supportUrl, setSupportUrl ] = useState('');
    const [ tgUrl, setTgUrl ] = useState('');
    const [ whatsappUrl, setWhatsappUrl ] = useState('');
    const [ isOldPage, setIsOldPage ] = useState(false);   

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        }   
        faqVersion({}, CallbackFaqVersion);  
        GetSupportInfo(); 

        if(isOldPage){ 
            GetFaq();     
        }
        let pageContent = localStorage.getItem('pageContent');  
        if(pageContent){
            let parsedData = JSON.parse(pageContent);
            setList(parsedData);
        }
        // GetPages(); 
        // eslint-disable-next-line
    }, [isOldPage]); 
  

    const GetSupportInfo = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo;  
            // let pay_name = "trc20"; 
            GetSupport({}, CallbackGetSupport);  
        }
    } 
    
    const CallbackGetSupport = (returnData) => {  
        if(returnData.code == 1)
        {  
            var data = returnData.data; 
            setTgUrl(data.tg);   
            setSupportUrl(data.liveChat);   
            setWhatsappUrl(data.whatsapp);   
        }  
    } 
 
    const CallbackFaqVersion = (returnData) => {  
        
        if(returnData.code === 1)
        { 
            var data = returnData.data;
 
            let faqVers = localStorage.getItem('faqVers');  
              
            if(faqVers !== data.version){ 
                setIsOldPage(true);
                localStorage.setItem('faqVers', data.version)
            }  
        }   
    } 

    const support = (e) => { 
        e.preventDefault();
        window.open(supportUrl, '_blank'); 
    }; 
    const tg = (e) => { 
        e.preventDefault();
        window.open(tgUrl, '_blank'); 
    }; 
    const wapps = (e) => { 
        e.preventDefault();
        window.open(whatsappUrl, '_blank'); 
    }; 
 
    const GetFaq = async e => {    
        faq({
        }, CallbackFaq);  
    } 
  
    const [ list, setList ] = useState([]);  

    const CallbackFaq = (returnData) => {  
        if(returnData.code === 1)
        {    
            var data = returnData.data; 
            localStorage.setItem("pageContent", JSON.stringify(data)); 
            setList(data)
        } 
    } 

    function getContent(id) {
        // Get the stored JSON string from localStorage
        const storedList = localStorage.getItem("pageContent");
      
        // Parse the JSON string back into an array
        const list = JSON.parse(storedList) || [];
      
        // Find the object with the matching id
        const item = list.find((item) => item.id === id);
      
        return item;
    }

    return (
    <>
        <div className="page-wrapper service-wrapper">  
            <InnerNav/>

            <div className="module">  
                <div className="user_faq_list">   
                {list.length > 0 ? (
                    <Collapse initExpanded={['1']} accordion>
                    {list.map((item) => (
                        <Collapse.Item title={item.title} name={item.id} key={item.id}>
                        {item.content}
                        </Collapse.Item>
                    ))}
                    </Collapse>
                ) : (
                    <div className="records_tabs">
                    <div className="text-center">{t('No record available')}</div>
                    </div>
                )}
                </div> 
            </div> 
        </div>  
    </>
    );
};