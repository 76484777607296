
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    

import { ReactComponent as IconMoney } from '../../assets/images/icon_money.svg'; 
import { ReactComponent as IconCalendar } from '../../assets/images/icon_calendar.svg'; 
import { ReactComponent as IconDownload } from '../../assets/images/icon_download.svg'; 
import { ReactComponent as IconUpload } from '../../assets/images/icon_uploadbtn.svg'; 

import { taskSubmit, taskDownload } from '../../Services/User';  

import { useTranslation } from 'react-i18next'; 
  
import { 
    Button,  
    Row,
    Col, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Alert
} from 'reactstrap';


import { Tabs , Popup, Cell } from 'react-vant'
import { Notify } from 'notiflix/build/notiflix-notify-aio';
   
export default function AppCard({ item, type, toUpdate, canDo }) {  
 
    const navigate = useNavigate();  
    const { t } = useTranslation();

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [ appId, setAppId ] = useState("");  
    const [ detailId, setDetailId ] = useState("");  
    const [ detailTitle, setDetailTitle ] = useState("");  
    const [ detailUrl, setDetailUrl ] = useState("");  
    const [ detailCover, setDetailCover ] = useState("");  
    const [ disabled, setDisabled ] = useState(true);  
    const [ candownload, setCandownload ] = useState(true);  
    const [ taskSrc, setTaskSrc ] = useState(null); 
    const [ isComplete, setIsComplete ] = useState(false);  
    const [ taskError, setTaskError ] = useState(false);  
    const [ errorMessage, setErrorMessage ] = useState("");  
    const [ taskErrorMessage, setTaskErrorMessage ] = useState("");  
    const [ taskAmount, setTaskAmount ] = useState(0);  
    const [ taskReward, setTaskReward ] = useState(0);  
    const [ downloadUrl, setDownloadUrl ] = useState(null);  
    
    useEffect(() => {    
        if(canDo == 0){
            setCandownload(false)
        } 
    }, [canDo]); 

    const appDetail = (item) => {
        setAppId(item.app_id)
        setDetailId(item.task_id)
        setDetailTitle(item.app_title)
        setDetailUrl(item.app_download_url)
        setDetailCover(item.app_cover) 
        setModal(true)

    }

    const downloadApp = () => {
        setDisabled(false);
        PostTaskDownload();
    }

    const PostTaskDownload = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            let task_id = appId; 

            taskDownload({
                task_id,
                token
            }, CallbackPostTaskDownload);  
        }
    } 
    
    const CallbackPostTaskDownload = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setDownloadUrl(detailUrl);
            window.open(detailUrl, '_blank');  
        } 
    }
 
    const PostTaskSubmit = (uploadData) => {
        console.log("PostTaskSubmit")
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            let task_id     = detailId; 
            let task_upload = uploadData; 
 
            taskSubmit({
                task_id,
                token,
                task_upload
            }, CallbackPostTaskSubmit);  
        }
    } 
    
    const CallbackPostTaskSubmit = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else
            { 
                setTaskError(true)
                setTaskErrorMessage(returnData.message);  
                Notify.failure(returnData.message); 
                setTaskSrc("");  
                setDisabled(true);  
                setTaskAmount(returnData.data.total_order);  
                setTaskReward(returnData.data.reward);  
            }
        } 
        else{  
            var data = returnData.data;  
            setCandownload(false)
            setIsComplete(true) 
            toUpdate()
        } 
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
          const reader = new FileReader();
          
          reader.onloadend = () => {
            setTaskSrc((prevAvatarSrc) => {
              // Use the callback to make sure we get the updated state
              PostTaskSubmit(reader.result); // Log the result here if needed
              return reader.result;
            }); 
          };
          reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => { 
        document.getElementById('fileInput').click();
    };
 
    return (
    <> 
        <div className="box-card mt-3">
            <div className="app-card-header">
                <span className="app-card-image">
                    <img src={item.app_cover} height={100}/>
                </span>
                <div className="app-card-info">
                    <h4>{item.app_title}</h4> 
                    {type === "task" && (
                        item.status === -1 ? (
                            <Button color="primary" className="task-badge" onClick={() => appDetail(item)} >{t("Download Now")}</Button>
                        ) : ( 
                            <span className={`task-detail-badge badge badge-${item.statusClass}`}>{item.statusName}</span>
                        )
                    )}
                </div>
            </div>  
            <div className="app-card-footer">
                <Row>
                    <Col>
                        <span className="app-card-value"><IconCalendar /><span>{item.app_total}</span></span>
                        <span className="app-card-title">{t('Task Value')}</span>
                    </Col>
                    <Col>
                        <span className="app-card-value"><IconMoney /><span>{item.app_reward}</span></span>
                        <span className="app-card-title">{t('Commission')}</span>
                    </Col>
                    <Col>
                        <span className="app-card-value"><IconDownload /><span>{item.app_total_download}</span></span>
                        <span className="app-card-title">{t('Downloads')}</span>
                    </Col>
                </Row>
            </div>  

            <Modal className="app-detail" isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>{t("Detail")}</ModalHeader>
                <ModalBody>
                    <div className="app-card-detail">
                        <div className="app-card-etail-image">
                            <img src={detailCover} height={100}/>
                        </div>
                        <h4>{detailTitle}</h4>
                        <Button size="sm" className="btn-upload" color="primary" onClick={downloadApp}>
                            {t("Start Task Now")} 
                        </Button>
                        {downloadUrl !== null && 
                        (
                            <a className="btn-upload btn btn-primary btn-sm ml-1"  href={downloadUrl} target="_blank">
                                {t("Download Now")} 
                            </a>
                        )}
                    </div>
                    <div className="app-card-action">
                        {!isComplete ? ( 
                            <> 
                                <p>Take a screenshot from the app and upload it to start the review process.</p>
                                {taskSrc && ( 
                                    <span className="task-upload">
                                        <img src={`${taskSrc}`} alt="Task" className="task-image"  height={60}/>
                                    </span>
                                )}
                                <Button  disabled={disabled} className="btn-upload" color="primary" onClick={handleUploadClick}>
                                    <IconUpload/> {t("Upload Picture")} 
                                </Button>
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                {disabled && (
                                    <div className="remark">** {t("Please download apps to continue")}</div>
                                )} 
                                {taskError === true && (
                                    <>
                                        <Alert color="danger" className="mt-3">{taskErrorMessage}</Alert> 
                                                            
                                        <Row className="task-detail">
                                            <Col>
                                                <span className="app-card-title">{t('Task Value')}</span>
                                                <span className="app-card-value"><span>{taskAmount}</span></span>
                                            </Col>
                                            <Col>
                                                <span className="app-card-title">{t('Commission')}</span>
                                                <span className="app-card-value"><span>{taskReward}</span></span>
                                            </Col> 
                                        </Row>
                                    </>

                                )}
                            </>
                        ): (
                            <Button  disabled={true} className="btn-upload" color="primary">
                                {t("Complete. Waiting for audit")} 
                            </Button>
                        )}
                    </div>
                </ModalBody> 
            </Modal>
        </div>  
    </>
    );
};