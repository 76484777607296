
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import TextField from '@mui/material/TextField'; 
import InputAdornment from '@mui/material/InputAdornment';
import QRCode from 'qrcode.react';

import { Profile } from '../../Services/User';  
  
import InnerNav from '../InnerNav';   
import { useTranslation } from 'react-i18next'; 
 
import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";


import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function RechargeTron() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();   
    const [ walletType, setWalletType ] = useState("");  
    const [ walletAddress, setWalletAddress ] = useState("");  
    const [ isTaxable, setIsTaxable ] = useState(0);  
    const [ taxType, setTaxType ] = useState("USDT-TRC");  
    const [ taxAddress, setTaxAddress ] = useState("");  
    const [ taxAddressType, setTaxAddressType ] = useState("");  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();  
        
        // setLoading2(true);
        // setLoading(true);
        // eslint-disable-next-line
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;  
            if(data.wallet_type){
                setWalletType(data.wallet_type);  
            }
            if(data.wallet_address){
                setWalletAddress(data.wallet_address);  
            }   
        } 
    } 
  
    const copyToClipboard  = () => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = walletAddress;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        notyf.success(t("Wallet address have copy to clipboard"));   
    }; 

    const copyTaxToClipboard  = () => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = taxAddress;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        notyf.success(t("Tax address have copy to clipboard"));   
    }; 
  
    return (
        <> 
            <div className="page-wrapper account-wrapper">   
                <InnerNav title={t("Deposit")} path="my" />
           
                <div className="form-wrapper">     
                    <div className="wallet-info"> 
                        <h3>{t("Make Payment to Address")}:</h3>    
                        <QRCode className="mt-3 mb-3" value={walletAddress} />
                        <h4>{walletType}</h4>  
                    </div>
          
                    <div className="form-group mt-5">   
                        <FormGroup className="input-invite-row">
                            <label>{t("Wallet Address")}</label>
                            <div className="input-invite">
                                <Input 
                                    placeholder={t("Wallet Address")}   
                                    value={walletAddress}  
                                    disabled
                                    />
                                <Button className="btn-copy" color="primary" onClick={copyToClipboard} >{t("Copy")}</Button>
                            </div>
                        </FormGroup>  
                    </div>  
                </div>    
            </div> 
        </>
    );
};