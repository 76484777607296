import { useNavigate } from "react-router-dom";   

import { useTranslation } from 'react-i18next'; 

const InnerNav = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

 
  const goto = () => {
    if(props.path){
      navigate('/' + props.path);
    } else {
      window.history.back(); // Navigate back to the previous page
    }
  };
 
  return (
    <>
       <div className="top-header">
        <div className="inner-top-nav">
            <span className="btn-back" onClick={() => goto()}>
                <i className="fa-solid fa-arrow-left"></i> 
            </span> 
            <span className="nav-title">{props.title ? props.title : ''}</span> 
            <span></span> 
        </div> 
      </div>
    </> 
  );
}

export default InnerNav;