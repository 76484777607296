import React, {useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";    
import { Popup, Cell } from 'react-vant'
import LoadingSpinner from '../LoadingSpinner'; 

import { completeGoogleAccount, Profile, resendCode, verifyCode } from '../../Services/User'
import { Notyf } from "notyf";  
import { useTranslation } from 'react-i18next'; 
 
import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";
  
const notyf = new Notyf({duration: 5000}); 
    
export default function CompleteAccount() {    
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);   
    const [email, setEmail] = useState('');     
    const [isSuccess, setIsSuccess] = useState(false);   
    const inputs = [useRef(null), useRef(null), useRef(null), useRef(null)];
 

    const [inputValues, setInputValues] = useState(['', '', '', '']);
        
    const navigate = useNavigate();

    useEffect(() => { 
        const storedProfile = localStorage.getItem('googleLogin');
        if (storedProfile) {
          let profile = JSON.parse(storedProfile);
          setEmail(profile.email);
        } else { 
            navigate('/login');
        }
    }, []);
 
      
    const [invite_code, setInvite_code] = useState(''); 
    const [username, setUsername] = useState('');  
    const [tel, setTel] = useState('');    
    const [password, setPassword] = useState('');   
    const [pwdConfirm, setPwdConfirm] = useState('');    
    const [ showPs, setShowPs] = useState(false); 
       

    const SendCompleteAccount = async e => {    
    
        e.preventDefault(); 

        if(password !==  pwdConfirm)
        {
            notyf.error(t("Invalid password confirmation")); 
            return false;
        }     
        setLoading(true); 

        let storedProfile1 = localStorage.getItem('googleLogin'); 
        let profileData = JSON.parse(storedProfile1);
   
        let pwd = password; 
        let email = profileData.email;
        let lastname = profileData.family_name;
        let firstname = profileData.given_name;
        let gid = profileData.id;
        let username = lastname+"_"+firstname;
        let avatar = profileData.picture;
    
        completeGoogleAccount({
            email,
            lastname,
            firstname,
            gid,
            username,
            avatar,
            pwd,
            pwdConfirm,
            invite_code
        }, CallbackCompleteAccount);  
    
    } 
    
    const CallbackCompleteAccount = (returnData) => {  
        setLoading(false);
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else{ 
                notyf.error(returnData.message); 
            } 
        } 
        else{  
            var userdata = returnData.data; 
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', userdata.token);  
            if(userdata.is_verify === 0){
                navigate('/verify');
            } else {
                navigate('/home');
            }
        } 
    } 
  
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
        
    useEffect(() => {
        setIsDisabled(password === '' || pwdConfirm === '' || invite_code === ''); 
    }, [password, pwdConfirm, invite_code]);
  
 
    const goto = (path) => { 
        navigate('/' + path);
    }; 
    
    const toggleShowPs = () => {
        setShowPs((showPs) => !showPs); 
    }; 

    return( 
        <div className="page-wrapper register-page"> 
            {loading ? <LoadingSpinner /> : ''} 
             
            <div className="inner-top-nav">
                <span className="btn-back" onClick={() => goto("login")}><i className="fa-solid fa-arrow-left"></i></span> 
                <span className="nav-title">{t("Complete Account")}</span>
                <span></span>
            </div>
 
            <div className="form-info">   
                <form className="form" onSubmit={SendCompleteAccount} >   
                    <FormGroup className="mb-3">
                        <label>{t("Email")}</label>
                        <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <i className="fa-regular fa-envelope"></i>
                            </InputGroupText> 
                            <Input 
                            placeholder={t("Enter Email")}  
                            name="email"
                            type="email"
                            value={email}
                            disabled={true} 
                            />
                        </InputGroup>
                    </FormGroup> 
                    <FormGroup className="mb-3">
                        <label>{t("Password")}</label>
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-solid fa-unlock-keyhole"></i>
                        </InputGroupText> 
                            <Input 
                            placeholder={t("Enter Password")}  
                            name="password"
                            type={showPs ? 'text' : 'password'}
                            value={password}
                            onChange={(e)=>inputChangeHandler(setPassword, e)}    
                            />
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                <i className="fas fa-eye"></i>
                            ): (
                                <i className="fas fa-eye-slash"></i> 
                            )}
                            </InputGroupText> 
                        </InputGroup>
                    </FormGroup> 
                    <FormGroup className="mb-3">
                        <label>{t("Password Confirmation")}</label>
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-solid fa-unlock-keyhole"></i>
                        </InputGroupText> 
                            <Input 
                            placeholder={t("Enter Password Confirmation")}  
                            name="password"
                            type={showPs ? 'text' : 'password'}
                            value={pwdConfirm}
                            onChange={(e)=>inputChangeHandler(setPwdConfirm, e)}    
                            />
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                <i className="fas fa-eye"></i>
                            ): (
                                <i className="fas fa-eye-slash"></i> 
                            )}
                            </InputGroupText> 
                        </InputGroup>
                    </FormGroup>  
                    <FormGroup className="mb-3">
                        <label>{t("Invitation code")}</label>
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-solid fa-envelope-open-text"></i>
                        </InputGroupText> 
                            <Input 
                            placeholder={t("Invitation code")}   
                            id="referralCode" 
                            onChange={(e)=>inputChangeHandler(setInvite_code, e)}    
                            />
                        </InputGroup>
                    </FormGroup> 
    
                    <div className="form-action btn-block pt-3">  
                        <Button disabled={isDisabled} color="primary" >{t('Submit')}</Button> 
                    </div>     
                </form>   
            </div> 
 
            <Popup
                visible={isSuccess} 
                style={{ height: '70%' }}
                position='bottom'
                round 
            >
                <div className="form-popup">   
                    <img src={"/images/icon_success.png"} height={110} alt={t("Email Verification Success")}/>

                    <h1>{t("Registration Successful")}</h1>
                    <h4>{t("Congratulations! your account has created. Login now to experience the app ")}</h4>
                    <div className="form-action btn-block pt-3">  
                        <Button onClick={() => goto('home')} color="primary" >{t('Go To Homepage')}</Button> 
                    </div>      
                </div> 
            </Popup>
             
        </div> 
    );
} 