
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
  
import { Profile, Pages, Banner, PageVersion, BannerVersion } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';  
import TaskAILevel from './TaskAILevel';  
import TaskCompare from './TaskCompare';  
import { useTranslation } from 'react-i18next'; 
import { Divider } from 'react-vant';

import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

import { Tabs , Popup, Cell } from 'react-vant'
   
export default function TaskOldMember() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken(); 
    const [ username, setUsername ] = useState(""); 
    const [ avatar, setAvatar ] = useState("");  
    const [ balance, setBalance ] = useState(""); 
    const [ invitecode, setInvitecode ] = useState(""); 
    const [ vip, setVip ] = useState(0); 
    const [ showInvite, setShowInvite ] = useState(false); 
    const [ taskCount, setTaskCount ] = useState(0); 
    const [ tab, setTab] = useState("ailevel");
    const [ isNew, setIsNew] = useState(0);
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        // eslint-disable-next-line
    }, []); 
 
    const goto = (path) => { 
        navigate('/' + path);
    }; 
   
    return (
    <>   
        <div className="module"> 
            <div className="task-intro"> 
                <div className="task-intro-wrapper" style={{ backgroundImage: "url('images/banner_AI.png')" }}> 
                    <div className="task-intro-info"> 
                        <h5>{t("AI Automated Trading Market")}</h5>
                    </div>
                </div> 
            </div>
        </div> 

        <div className="task-center">
            <div className="module">
                <div className="card-header mt-5">
                    <div>
                        <h4>{t("AI Tasks")}</h4>
                        <h5>({taskCount}){t("Tasks for you")}</h5>
                    </div>
                    <div className="task-action">
                        <span className={tab === 'ailevel'?'active':''} onClick={() => setTab("ailevel")}>{t("AI Levels")}</span>
                        <span className={tab === 'compare'?'active':''} onClick={() => setTab("compare")}>{t("Compare")}</span>
                    </div> 
                </div>  
            </div> 
        </div> 

        {tab == "ailevel" ? (
            <TaskAILevel />
        ) : (
            <TaskCompare />
        )} 
    </>
    );
};