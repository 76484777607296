import React from 'react';  
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'; 
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
 
function DownlineComponent({ item }) {
 
  const { t } = useTranslation();
  return (
    <div>
        <div className="records_tabs"  key={item.id}>
          {item !== null ? (  
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div className="downline-info"><label>{t('Name')}</label><p>{item.username}</p></div>
                </Grid>
                <Grid item xs={4}>
                  <div className="downline-info"><label>{t('Recharge')}</label><p>{item.chongzhi}</p></div> 
                </Grid>
                <Grid item xs={4}>
                  <div className="downline-info"><label>{t('Withdraw')}</label><p>{item.tixian}</p></div> 
                </Grid>
                <Grid item xs={4}>
                  <div className="downline-info"><label>{t('Tel')}</label><p>{item.tel}</p></div> 
                </Grid>
                <Grid item xs={4}>
                  <div className="downline-info"><label>{t('Recommended number')}</label><p>{item.childs}</p></div> 
                </Grid>
                <Grid item xs={4}>
                  <div className="downline-info"><label>{t('Register time')}</label><p>{item.datetime}</p></div> 
                </Grid>
              </Grid>  
          ):''} 
        </div>
    </div>
  );
} 
export default DownlineComponent;
