import CryptoJS from "crypto-js"; 
import md5 from 'md5';
import json  from 'json-keys-sort'; 


export const doSomethingWithInput = (theInput) => {
   //Do something with the input
   return theInput;
};
 
const secretKEY = process.env.REACT_APP_AESKEY;  
const secretIV = process.env.REACT_APP_AESIV;  
const signKey = process.env.REACT_APP_SIGNKEY;  
 
export const encryptData = (postJson, suffix) => {
    let IV1 = secretIV+suffix
    let IV = CryptoJS.enc.Utf8.parse(IV1);
    let key1 = CryptoJS.enc.Utf8.parse(secretKEY); 
    var postdata = JSON.stringify(postJson)  
    let encrypted = CryptoJS.AES.encrypt(postdata, key1, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString(); 
};

export const decryptData = (postdata, suffix) => {
    let IV1 = secretIV+suffix
    let IV = CryptoJS.enc.Utf8.parse(IV1);
    let key1 = CryptoJS.enc.Utf8.parse(secretKEY);  
    let decrypted = CryptoJS.AES.decrypt(postdata, key1, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }); 
    return decrypted.toString(CryptoJS.enc.Utf8)
};

export const genSign = (postJson) => { 
    var sign = ""; 
    postJson = json.sort(postJson, true) 
    Object.entries(postJson).forEach((entry) => {
        const [key, value] = entry; 
        sign += key+"="+value+"&"
    });
    
    sign = sign + signKey;  
    return md5(sign)
}
export function logs(message) {
    if(process.env.REACT_APP_ENV === 'dev')
    {
        console.log(message);
    } 
} 

// encrypt(str,key,IV) {
//     // keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
//     let key1 = CryptoJS.enc.Utf8.parse(keyStr);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
//     let iv = CryptoJS.enc.Utf8.parse(IV);
//     let encrypted = CryptoJS.AES.encrypt(str, key1, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
//     return encrypted.toString();
// }, //秘钥必须为：8/16/32位

// //解密
// decrypt(str,key,IV) {
//     // keyStr = keyStr ? keyStr : 'abcdefgabcdefg12'
//     let key1 = CryptoJS.enc.Utf8.parse(keyStr);
//     let iv = CryptoJS.enc.Utf8.parse(IV);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
//     let decrypted = CryptoJS.AES.decrypt(str, key1, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
//     // return CryptoJS.enc.Utf8.stringify(decrypt).toString();
//     return decrypted.toString(CryptoJS.enc.Utf8)
// }