
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'     
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { Radio } from 'react-vant';
import { useTranslation } from "react-i18next";

import { 
    Button, 
    FormGroup, 
    Input, 
    Label,
    InputGroup, 
} from "reactstrap";
  

import { UpdatetLang, Profile } from '../../Services/User';  
import LoadingSpinner from '../LoadingSpinner';  
import InnerNav from '../InnerNav';   
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Language() {  
    const navigate = useNavigate();  
    const { i18n } = useTranslation(); 
    const { t }    = useTranslation();
    
    const [loading, setLoading] = useState(false)  
    const [lang, setLang] = useState('en'); 
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setLang(data.lang);  
        } 
    }  

    const handleUpdateLang = async e => {    
        e.preventDefault();      
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        setLoading(true);
        let token = tokenInfo;
            
        i18n.changeLanguage(lang);
        UpdatetLang({ 
            lang,
            token
        }, CallbackUpdatetLang);
    } 
 
    const CallbackUpdatetLang = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data;
            notyf.success(t(returnData.message));  
            localStorage.setItem('lang', data.lang);  
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 

    const handleChange = (event) => {
        setLang(event);  
    };
    const languageOptions = [
        { value: 'en', label: 'English' },
        { value: 'ar', label: 'Arabic' },
        { value: 'zh', label: 'Traditional Chinese' },
        { value: 'pt', label: 'Portuguese' },
        { value: 'es', label: 'Spanish' },
        { value: 'fr', label: 'French' },
        { value: 'de', label: 'German' },
        { value: 'th', label: 'Thai' },
        { value: 'hi', label: 'Hindi' },
        { value: 'ru', label: 'Russian' },
        { value: 'bn', label: 'Bengali' },
        { value: 'ja', label: 'Japanese' },
        { value: 'ko', label: 'Korean' },
    ];
    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper account-wrapper">  

                <InnerNav title={t('Change Language')} path="my"/>
           
                  
           
                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleUpdateLang} >   
                        <Radio.Group defaultValue={lang} onChange={handleChange}>
                            {languageOptions.map((option) => ( 
                            <Radio className="radio-row" key={option.value} name={option.value}>{option.label}</Radio> 
                            ))} 
                        </Radio.Group>
           
                        <div className="form-action btn-block">  
                            <Button type="submit" className="btn btn-primary" variant="contained">{t('Submit')}</Button>
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};