
import React, {useEffect,useState,useRef } from 'react';

import { useNavigate } from 'react-router-dom'      
import { SetPassword, Profile } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner'; 
import InnerNav from '../InnerNav';   
import { useTranslation } from 'react-i18next'; 
 
import { ReactComponent as IconUser } from '../../assets/images/icon_user.svg'; 
import { ReactComponent as IconLock } from '../../assets/images/icon_lock.svg'; 
import { ReactComponent as IconInfo } from '../../assets/images/icon_info.svg'; 
import { ReactComponent as IconEyeO } from '../../assets/images/icon_deye_o.svg'; 
import { ReactComponent as IconEyeC } from '../../assets/images/icon_deye_c.svg'; 

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap";
  

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Password() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
 
    const [ loading, setLoading] = React.useState(false)  
    const [ isDisabled, setIsDisabled] = useState(true)  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        var data = returnData.data;
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        }  
    } 

    const [currentpassword, setCurrentpassword] = useState('');   
    const [password, setPassword] = useState('');   
    const [confirmpassword, setConfirmpassword] = useState('');   
    const [ showPs, setShowPs] = useState(false); 
  
    const handleSetPassword = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');  
        let current_password = currentpassword
        let password_confirmation = confirmpassword

        SetPassword({ 
            token,
            current_password,
            password,
            password_confirmation
        }, CallbackSetPassword);
    } 
 
    const CallbackSetPassword = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        var data = returnData.data; 
        if(code === 1)
        { 
            notyf.success(data.result);  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
     
    useEffect(() => { 
        setIsDisabled(currentpassword === '' || confirmpassword === '' || password === '');
    }, [ currentpassword, password, confirmpassword]);

    const toggleShowPs = () => {
        setShowPs((showPs) => !showPs); 
    }; 

    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper account-wrapper">   
             
                <InnerNav path="security-settings" title={t("Change Account Password")}/>
                 
                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleSetPassword} >   
                   
                        <FormGroup className="mb-3">
                            <label>{t("Current Password")}</label>
                            <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <IconLock/>
                            </InputGroupText> 
                            <Input 
                                placeholder={t("Enter Current Password")}  
                                name="password"
                                type={showPs ? 'text' : 'password'}
                                value={confirmpassword}
                                onChange={(e)=>inputChangeHandler(setCurrentpassword, e)}    
                            />
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                <IconEyeO/> 
                                ): (
                                <IconEyeC/> 
                            )}
                            </InputGroupText> 
                            </InputGroup>
                        </FormGroup>  
                  
                        <FormGroup className="mb-3">
                            <label>{t("Password")}</label>
                            <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <IconLock/>
                            </InputGroupText> 
                            <Input 
                                placeholder={t("Enter Password")}  
                                name="password"
                                type={showPs ? 'text' : 'password'}
                                value={password}
                                onChange={(e)=>inputChangeHandler(setPassword, e)}    
                            />
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                <IconEyeO/> 
                                ): (
                                <IconEyeC/> 
                            )}
                            </InputGroupText> 
                            </InputGroup>
                        </FormGroup> 
                        <FormGroup className="mb-3">
                            <label>{t("Password Confirmation")}</label>
                            <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <IconLock/>
                            </InputGroupText> 
                            <Input 
                                placeholder={t("Enter Password Confirmation")}  
                                name="password"
                                type={showPs ? 'text' : 'password'}
                                value={confirmpassword}
                                onChange={(e)=>inputChangeHandler(setConfirmpassword, e)}    
                            />
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                <IconEyeO/> 
                                ): (
                                <IconEyeC/> 
                            )}
                            </InputGroupText> 
                            </InputGroup>
                        </FormGroup> 
                      
                        <div className="form-action btn-block mt-5">  
                            <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t('Submit')}</Button> 
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};