
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import TextField from '@mui/material/TextField';  

import { GetBank, SetBank, Profile,  country, countryVersion, countryCode } from '../../Services/User';  

import LoadingSpinner from '../LoadingSpinner'; 
import { useTranslation } from 'react-i18next';
import { MuiTelInput } from 'mui-tel-input'
import InnerNav from '../InnerNav';   

import { ReactComponent as IconUser } from '../../assets/images/icon_user.svg'; 
import { ReactComponent as IconCoin } from '../../assets/images/icon_coin.svg'; 
import { ReactComponent as IconInfo } from '../../assets/images/icon_info.svg'; 
import { ReactComponent as IconEyeO } from '../../assets/images/icon_deye_o.svg'; 
import { ReactComponent as IconEyeC } from '../../assets/images/icon_deye_c.svg'; 

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap";
  

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Bank() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    const [ canupdate, setCanupdate ]   = useState(false);   
    const [ bankname, setBankname ]     = useState('');   
    const [ accountname, setAccountname ]   = useState('');   
    const [ accountnum, setAccountnum ]     = useState('');   
    const [ wallet_address, setWallet_address ]     = useState('');   
    const [ wallet_type, setWallet_type ]     = useState('');   
    const [ realname, setRealname ]     = useState("");   
    const [ tel, setTel ]               = useState("");   
    const [ mobilecode, setMobilecode ] = useState(0);   
    const [ isOldCountry, setIsOldCountry ] = useState(false);  
    const [allowedCountries, setAllowedCountries] = useState([]);
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserBank();   
      
        GetCountryCode();     

    }, [isOldCountry]); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserBank = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            GetBank({
                token
            }, CallbackGetBank);  
        }
    } 
    
    const handlePhoneChange = (newPhone) => {
        setTel(newPhone)
    }

    const CallbackGetBank = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            if(data ){
                setRealname(data.real_name);  
                setWallet_address(data.wallet_address);  
                setWallet_type(data.wallet_type);  
                setBankname(data.bankname);  
                setTel(data.mobile);  
                setMobilecode(data.mobilecode);  
                setCanupdate(false);
            } else {
                setWallet_type("trc20");
                setCanupdate(true);
                UserProfile();
            }
        } 
    } 

    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setRealname(data.real_name);  
        } 
    }   

    const [countries, setCountries] = useState([]);   

    const CheckCountryVersion = async e  => {   
        GetCountry();  
    }
     

    const GetCountry = async e => {    
        country({
        }, CallbackCountries);  
    }  

    const CallbackCountries = (returnData) => {  
        if(returnData.code === 1)
        {    
            var data = returnData.data; 
            localStorage.setItem("countries", JSON.stringify(data)); 
            setCountries(data)
        } 
    } 
    
    const handleBindBank = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');   
        SetBank({ 
            token,
            bankname,
            wallet_type,
            wallet_address,
            realname,
            tel,
            mobilecode
        }, CallbackBindBank);
    } 
 
    const CallbackBindBank = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data; 
            notyf.success(t(returnData.message));  
            UserBank();  
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
     
    const GetCountryCode = async e => {    
        countryCode({}, CallbackCountryCode);  
    } 
    
    const CallbackCountryCode = (returnData) => {  
        if(returnData.code == 1)
        {  
            var data = returnData.data;  
            setAllowedCountries(data);  
        }  
    } 
    useEffect(() => {
        setIsDisabled( wallet_type === '' ||  wallet_address === '' || realname === '' || tel === ''); 
    }, [wallet_type, wallet_address, realname, tel]);

    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper account-wrapper">  
      
                <InnerNav path="security-settings" title={t("Bind Wallet Address")}/>
                
                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleBindBank} >   
                        <FormGroup className="mb-3">
                            <label>{t("Real Name")}</label>
                            <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <IconUser/>
                            </InputGroupText> 
                            <Input 
                                placeholder={t("Real Name")}  
                                name="realname" 
                                value={realname}
                                onChange={(e)=>inputChangeHandler(setRealname, e)}   
                                disabled={!canupdate} 
                            /> 
                            </InputGroup>
                        </FormGroup>  

                        <FormGroup className="mb-3">
                            <label>{t("Phone number & Country")}</label> 
                            <MuiTelInput
                                    required 
                                    defaultCountry={'US'} // Set the default country (optional)
                                    value={tel}
                                    onChange={handlePhoneChange}
                                    autoComplete="off"
                                    onlyCountries={allowedCountries} 
                                    disabled={!canupdate} 
                                />   
                        </FormGroup>  
                        <FormGroup className="mb-3 disabled">
                            <label>{t("Wallet Type")}</label>
                            <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <i className="fa-solid fa-wallet"></i>
                            </InputGroupText> 
                            <Input 
                                placeholder={t("Wallet Type")}  
                                name="accountname" 
                                value={wallet_type} 
                                disabled
                            /> 
                            </InputGroup>
                        </FormGroup>  
                        <FormGroup className="mb-3">
                            <label>{t("Wallet Address")}</label>
                            <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <i className="fa-brands fa-btc"></i>
                            </InputGroupText> 
                            <Input 
                                placeholder={t("Wallet Address")}  
                                name="accountnum" 
                                value={wallet_address}  
                                onChange={(e)=>inputChangeHandler(setWallet_address, e)}   disabled={!canupdate} 
                            /> 
                            </InputGroup>
                        </FormGroup>  
 
                        <div className="form-action btn-block mt-5">  
                            {canupdate ? (
                                <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t('Submit')}</Button> 
                            ) : (
                                <div className="form-alert">{t('Please contact admin for update')}</div>
                            )}
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};