
import React, {useEffect,useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom'  
import { experimentalStyled as styled } from '@mui/material/styles'; 

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
    Alert
} from "reactstrap";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import { PieChart } from '@mui/x-charts/PieChart'; 
 
import { RotOrder, TradeOrder, PostOrderDetail, taskTodo, Profile, startTask } from '../../Services/User';  
import useToken from '../App/useToken'; 
import LoadingSpinner from '../LoadingSpinner';  
import InnerNav from '../InnerNav';   

import { Tabs , Popup, Cell, List } from 'react-vant'
import AppCard from './AppCard';  

import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 
import { ReactComponent as IconInfo } from '../../assets/images/icon_info.svg'; 
import { ReactComponent as IconBalance } from '../../assets/images/icon_balance.svg'; 
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 
 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));
  
const StyledText = styled('text')(({ theme }) => ({
    fill: "#1A1C1E",
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 31,
    fontWeight: 'bold',
  }));
  
const StyledText2 = styled('text')(({ theme }) => ({
    fill: "#6C7278",
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 11,
    width:'100px'
  }));
  
function PieCenterLabel({ children }) { 
    const { t } = useTranslation();
    
    return (<>
      <StyledText x={'50%'} y={'105px'}>
       {children}
      </StyledText>
      <StyledText2 x={'165px'} y={'130px'}>
       {t("Remaining Order")}
      </StyledText2>
    </>
    );
  }
export default function Shop() {  
 
    const userAgent = navigator.userAgent; 
    const { tier } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [vipName, setVipName] = useState(""); 
    const [amountFrozen, setAmountFrozen] = useState(0);
    const [todayOrder, setTodayOrder] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [accountBalance, setAccountBalance] = useState(0);
    const [commissionYesterday, setCommissionYesterday] = useState(0); 
    const [commission, setCommission] = useState(0); 
    const [pendingOrder, setPendingOrder] = useState(0); 
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [countdown, setCountdown] = useState(3); 
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [orderInfo, setOrderInfo] = useState(false);
    const [orderError, setOrderError] = useState(false);
    const [orderErrorMessage, setOrderErrorMessage] = useState([]);
    const [orderDetail, setOrderDetail] = useState([]);
    const [postorderBtn, setPostorderBtn] = useState(false);
    const [userData, setUserData] = useState([]); 

    const [cardNum, setCardNum] = useState("");
    const [userTotal, setUserTotal] = useState(0);
    const [remainOrder, setRemainOrder] = useState(0);

    
    const [tierId, setTierId] = useState(0);
    const [tierTitle, setTierTitle] = useState('');
    const [tierType, setTierType] = useState('vip');
    const [dailyMax, setDailyMax] = useState(0);
    const [roundMax, setRoundMax] = useState(0);
    const [todayLuckCount, setTodayLuckCount] = useState(0);
    const [roundCurrent, setRoundCurrent] = useState(0);
    const [isAi, setIsAi] = useState(0);
    const [order_count, setOrder_count] = useState(0);
    const [task_count, setTask_count] = useState(0);
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        GetRotOrder();   
        // eslint-disable-next-line
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
 
    const GetRotOrder = async e => {    
        GetTaskLists();   
        let token = localStorage.getItem('token');  

        if(token === null){ 
            navigate('/login');
        } 
        else {   
            let cid = tier
            RotOrder({
                token,
                cid,
            }, CallbackRotOrder);  
        }
    } 
    
    const CallbackRotOrder = (returnData) => {   
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setVipName(data.level_name);  
            setAccountBalance(data.account_balance);  
            setTodayOrder(parseFloat(data.today_task_total).toFixed(2));  
            setTotalOrder(data.order_num);  

            setCommissionYesterday(parseFloat(data.yes_user_yongjin).toFixed(2));  
            setPendingOrder(parseFloat(data.cash_gap_between_task).toFixed(2));  
            
            setCommission(parseFloat(data.today_commission_total).toFixed(2)); 
            setCardNum(data.card_num); 
            setAmountFrozen(parseFloat(data.lock_deal).toFixed(2));   
            setIsAi(data.is_ai); 

            setOrder_count(data.order_count); 
            setTask_count(data.task_count); 
            
            if(data.account_balance > 0){
                setIsDisabled(false);
            }
            
            if(data.can_trade === false){
                setIsDisabled(true);
                setError(true);
                setErrorMessage(data.trade_message); 
            }
        

            let total = parseFloat(data.user_purchases) + parseFloat(data.lock_deal) + parseFloat(data.day_deal) + parseFloat(data.yes_user_yongjin) + parseFloat(Math.abs(data.cash_gap_between_task)); 

            total = total.toFixed(2);

            let id0 = (data.user_purchases)
            let id1 = (data.lock_deal )
            let id2 = (data.day_deal )
            let id3 = (data.yes_user_yongjin )
            let id4 = (Math.abs(data.cash_gap_between_task) )

            let userSummary = [
                { id: 0, value: parseFloat(id0).toFixed(2), color: '#31B099', label: 'Purchased today' },
                { id: 1, value: parseFloat(id1).toFixed(2), color: '#E7854D',label: 'Amount frozen money' },
                { id: 2, value: parseFloat(id2).toFixed(2), color: '#C65468',label: 'Commission today' },
                { id: 4, value: parseFloat(id3).toFixed(2), color: '#FFC700',label: 'Purchased commission yesterday' },
                { id: 5, value: parseFloat(id4).toFixed(2), color: '#5DD597',label: 'Cash gap between task' },
            ]; 
            setUserTotal(total);
             
            const numericData = userSummary.map(item => ({
                ...item,
                value: parseFloat(item.value),
            })); 
            setUserData(numericData);

            let remaining = data.task_count - data.order_count;
            if(remaining <= 0){
                remaining = 0;
                setIsDisabled(true);
            }
            setRemainOrder(remaining); 
              
        } 
    } 
  
    const goto = (path) => { 
        navigate('/' + path);
    }; 
    
    const [ applists, setApplists] = useState([]); 
    const [ balance, setBalance] = useState(0);
    const [ progressTotal, setProgressTotal ] = useState("00"); 
    const [ progressBal, setProgressBal ] = useState("00"); 
    const [ progressVal, setProgressVal] = useState(0);  
    const [ finished, setFinished] = useState(true)
    const [ canDo, setCanDo] = useState(true) 
    const [ isLoading, setIsLoading] = useState(false)

    const UserProfile = async e => {    
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            
            setProgressVal(data.newtask_pal);
            setProgressBal(data.newtask_count);
            setProgressTotal(data.newtask_total); 
            setCommission(data.commission); 
            setBalance(data.balance); 

            if(progressBal == progressVal){
                setCanDo(false);
            }
        } 
    }  

    const isAndroid = () => {
        return /Android/i.test(userAgent);
    };

    const isIOS = () => {
        return /iPhone|iPad|iPod/i.test(userAgent);
    };
 
    const GetTaskLists = async e => {    
        if(isLoading){ 
            return
        }   
        setIsLoading(true);
 
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            let device;
            if(isIOS()){
                device = "ios";
            } 
            else{
                device = "android";
            }
            
            taskTodo({
                token,
                device
            }, CallbackGetTaskTodo);  
        }
    } 
    
    const CallbackGetTaskTodo = (returnData) => {  
        setIsLoading(false);
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setApplists(data.data);  
        } 
    }  


    const smartBuyer = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {   
            setIsDisabled(true);
            setLoading(true); 
            for (let i = countdown; i >= 1; i--) {
              setCountdown(i);
              await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
            }
             
            try { 
                setLoading2(true);
                let cid = tier
                await TradeOrder({
                    token,
                    cid
                }, CallbackSmartBuyer);   
            } catch (error) {
                notyf.error(t("Error submitting order"));  
            } finally {
                setLoading(false);
                setCountdown(3);
            }
 
        }
    } 
    
    const CallbackSmartBuyer = (returnData) => {   
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else{ 
                setLoading2(false);
                setError(true);
                setErrorMessage(returnData.message); 
            }
        } 
        else{  
            var lists = returnData.data;  
            GetTaskLists()
        } 
    } 
 
    
    const [status, setStatus] = useState('idle'); // idle, submitting, allocated, waiting
    const [countdown2, setCountdown2] = useState(0);
    const [loading3, setLoading3] = useState(false);

    const countdownDelay = async () => {
        for (let i = 3; i >= 1; i--) {
            setCountdown2(i);
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
        }
    };

    const startTaskAction = async (order_number) => {    
        
        setPostorderBtn(true)
        setOrderError(false)
        setOrderErrorMessage("")

        setLoading3(true);
        setStatus(t('submitting...'));
        setCountdown2(2);

        // Countdown before the next step
        await countdownDelay();
        
        setStatus(t('The remote host is being allocated...'));
        setCountdown2(3);

        // Countdown before the next step
        await countdownDelay();

        setStatus(t('Waiting for the merchant system to respond'));
        setCountdown2(2);

        // Countdown before sending the API request
        await countdownDelay();
        
        try {
            // Call your sendapi function here
            let tokenInfo = localStorage.getItem('token');  
            if(tokenInfo === null){ 
                navigate('/login');
            } 
            else {    
                setLoading3(false);
                setLoading2(true);
                let token = tokenInfo;  
                let device;
                if(isIOS()){
                    device = "ios";
                } 
                else{
                    device = "android";
                }
                
                startTask({
                    token,
                    device
                }, CallbackStartTask);   
            }
        } catch (error) {
            console.error(error); // Handle the error
        }
    
        // Reset status and countdown after completing the process
        setStatus('');
        setCountdown2(0);  
    } 
    
    const CallbackStartTask = (returnData) => {  
        setLoading2(false);
        setLoading3(false);
        setPostorderBtn(false);

        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else{
                setOrderError(true);
                setOrderErrorMessage(returnData.message);
                notyf.error(returnData.message); 
            }
        } 
        else{  
            var lists = returnData.data;   
            notyf.success(t("success")); 
            GetRotOrder()
        } 
    } 
    
  

    return (
        <>
            <div className="page-wrapper shop-wrapper">  
                 
                <InnerNav title={t("Tasks")} />
                
                <div className="module app_wrapper"> 
 
                    <div className="account-balance mb-3">
                        <div className="card">
                            <div className="card-header">
                                <span>{t("Guarda Balance")} <IconInfo /></span>
                                <span className="btn-more"><IconBalance/></span> 
                            </div>
                            <div className="card-body">
                                <div className="balance-amount">
                                    <span className="amount">$ {accountBalance}</span>
                                </div> 
                            </div>
                        </div>
                    </div>
                      
                    <div className="account-summary"> 
                        <div className="account-summary-info">  
                            <h4>{t("Recent Activity")}</h4>
                            <a className="more-link" onClick={() => goto('task-history')}   href="#">{t("See more")}<i className="fa-solid fa-arrow-right"></i></a>
                        </div>
                        {userTotal > 0 ?
                            <PieChart
                            series={[
                                {
                                    data: userData,
                                    paddingAngle: 2,
                                    innerRadius: 50,
                                    cornerRadius: 5,
                                    cx: 160,
                                    cy: 110,
                                },
                            ]}
                            slotProps={{
                                legend: { hidden: true }, 
                            }}
                            width={320}
                            height={260}
                            > 
                                <PieCenterLabel>{remainOrder}</PieCenterLabel>  
                          </PieChart>
                        :''}
                        <div className="account-summary-lists">  
                            <div className="order-list-items" style={{color:"#31B099"}}> 
                                <div className="order-list-item-title">{t('Total transaction amount')}</div>
                                <div className="order-list-item-value">{todayOrder}</div>
                            </div>
                            <div className="order-list-items" style={{color:"#E7854D"}}> 
                                <div className="order-list-item-title">{t('Amount frozen money')}</div>
                                <div className="order-list-item-value">{amountFrozen}</div>
                            </div>
                            <div className="order-list-items" style={{color:"#C65468"}}> 
                                <div className="order-list-item-title">{t('Commission today')}</div>
                                <div className="order-list-item-value">{commission}</div>
                            </div>
                            <div className="order-list-items" style={{color:"#FFC700"}}> 
                                <div className="order-list-item-title">{t('Purchased commission yesterday')}</div>
                                <div className="order-list-item-value">{commissionYesterday}</div>
                            </div>
                            <div className="order-list-items" style={{color:"#5DD597"}}>  
                                <div className="order-list-item-title">{t('Cash gap between task')}</div>
                                <div className="order-list-item-value">{pendingOrder}</div>
                            </div>
                            <div className="order-list-items" style={{color:"#5DD597"}}>  
                                <div className="order-list-item-title">{t('Task Count')}</div>
                                <div className="order-list-item-value">{order_count}/{task_count}</div>
                            </div>
                        </div> 
                    </div>
  
                    <div className="order-action">  
                        
                        <Button block type="submit" className="btn btn-primary" onClick={startTaskAction} disabled={isDisabled}  variant="contained">{t('Start Task')}
                            {isAi === 1 && (
                                <span>({t("AI")})</span>
                            ) }
                        </Button> 
                            {isAi === 0 && (
                                <p className="nav-link-ai" onClick={() => goto('deposit')} >{t("Upgrade to Ai Bot")}</p>
                            ) }
                    </div> 

                                
                    <div className="task-lists">
                    {applists.length > 0 ? (
                        <List finished={finished} onLoad={GetTaskLists} offset={0} loadingText={t("loading...")}>
                            {applists.map(item => (
                                <AppCard item={item} type={"task"} key={item.id} toUpdate={GetRotOrder} canDo={canDo}/>
                            ))}
                        </List>
                    ) : (
                        <div className="empty">{t("no task available")}</div>
                    )}
                    </div>
                    
                </div>
            </div>
            
            {error ?  
                <Alert color="warning" className=" fixed-bottom">{errorMessage}</Alert> 
            : ''} 
            {loading ? (
                
                <div className="loading">
                    <div className="loading-text">
                    <p>{t('Matched')}...{countdown}</p> 
                    </div>
                </div>
                
            ) :  '' }

            {loading2 ? <LoadingSpinner /> : ''}
   
            {loading3 ? (
                
                <div className="loading">
                    <div className="loading-text">
                        <div>
                        {status} 
                        </div>
                    </div>
                </div>
                
            ) :  '' }
        </>
    );
};