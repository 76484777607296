import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";    
import LoadingSpinner from '../LoadingSpinner';   

import {UserLogin, countryCode} from '../../Services/User'
import useToken from '../App/useToken';  
import InnerNav from '../InnerNav';   
  
 
import { Notyf } from "notyf"; 
import { useTranslation } from 'react-i18next'; 
import { Divider } from 'react-vant';
            
import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap";
  
const notyf = new Notyf({duration: 5000}); 
    
export default function Login() {   
 
    const { t } = useTranslation();
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);  
    const [supportUrl, setSupportUrl] = useState(''); 
    const [ showPs, setShowPs] = useState(false);  
    const [email, setEmail] = useState('');   
    const [password, setPassword] = useState('');    
  
    const { token, setToken } = useToken();
    const navigate = useNavigate();

    useEffect(() => { 
        if(token){ 
            navigate('/home');
        }  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = async e => { 
        e.preventDefault();      
        setLoading(true);  
    
        UserLogin({ 
            email,
            password
        }, CallbackLogin);
    } 
 
    const CallbackLogin = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var userdata = returnData.data;
            setToken(userdata.token); 
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', userdata.token);  
            if(userdata.is_verify === 0){
                navigate('/verify');
            } else {
                navigate('/home');
            }
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }
 
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    }     

    useEffect(() => {
        setIsDisabled(email === '' || password === ''  ); 
    }, [email, password]);
  
    const support = (e) => { 
        e.preventDefault();
        window.open(supportUrl, '_blank'); 
    };
  
    const register = () => { 
        navigate('/register');
    }; 

    const goto = (path) => { 
        navigate('/' + path);
    }; 
 
    const toggleShowPs = () => {
        showPs((showPs) => !showPs); 
    };

    const authBy = (path) => { 
        alert("copming soon")
    }; 


    return( 
        <div className="page-wrapper register-page"> 
            {loading ? <LoadingSpinner /> : ''} 
                        
            <InnerNav />
 
            <div className="form-info">   
                <form className="form" onSubmit={handleLogin} >  
                    <div className="login-heading">   
                        <h1>{t("Hello Welldux!")}</h1>
                        <h4>{t("Create an account to Sign In.")}</h4>
                    </div>
                    <FormGroup className="mb-3">
                        <label>{t("Email")}</label>
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-regular fa-envelope"></i>
                        </InputGroupText> 
                          <Input 
                            placeholder={t("Enter Email")}  
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e)=>inputChangeHandler(setEmail, e)}    
                          />
                        </InputGroup>
                    </FormGroup> 
                    <FormGroup className="mb-3">
                        <label>{t("Password")}</label>
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-solid fa-unlock-keyhole"></i>
                        </InputGroupText> 
                          <Input 
                            placeholder={t("Enter Password")}  
                            name="password"
                            type={showPs ? 'text' : 'password'}
                            value={password}
                            onChange={(e)=>inputChangeHandler(setPassword, e)}    
                          />
                          <InputGroupText  onClick={toggleShowPs}>
                          {showPs ?(
                                <i className="fas fa-eye"></i>
                            ): (
                              <i className="fas fa-eye-slash"></i> 
                          )}
                          </InputGroupText> 
                        </InputGroup>
                    </FormGroup> 
  
                    <div className="form-add-action">  
                        <span className="nav-link" onClick={support} >
                        {t("Forgot Password?")}
                        </span>
                    </div>
                    <div className="form-action btn-block">  
                        <Button type="submit" disabled={isDisabled} color="primary" >{t('Login')}</Button> 
                    </div>   
                    <div className="form-separate">  
                        <Divider>{t("Or")}</Divider>
                    </div>
 
                    <div className="nav-social-action">  
                        <span className="nav-social" onClick={() => authBy("facebook")}>
                            <img src={"images/icon_facebook.png"} height={70}/>
                        </span>
                        <span className="nav-social" onClick={() => authBy("google")}>
                            <img src={"images/icon_google.png"} height={70}/>
                        </span>
                        <span className="nav-social" onClick={() => authBy("ios")}>
                            <img src={"images/icon_apple.png"} height={70}/>
                        </span>
                    </div>      
 
                    <div className="nav-reg">  
                        {t('Don’t have an account?')}<span className="nav-link"  size="large" onClick={register}>{t("Register")}</span> 
                    </div>      
                </form> 
            </div>  
        </div>
        
    );
} 