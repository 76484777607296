
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
import '../Common.css'; 
import { Profile, Pages, Banner, PageVersion, BannerVersion } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';  
import TaskAILevel from './TaskAILevel';  
import TaskCompare from './TaskCompare';  
import TaskOldMember from './TaskOldMember';  
import TaskNewMember from './TaskNewMember';  
import { useTranslation } from 'react-i18next'; 
import { Divider } from 'react-vant';

import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

import { Tabs , Popup, Cell } from 'react-vant'
   
export default function Task() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken(); 
    const [ username, setUsername ] = useState(""); 
    const [ avatar, setAvatar ] = useState("");  
    const [ balance, setBalance ] = useState(""); 
    const [ invitecode, setInvitecode ] = useState(""); 
    const [ vip, setVip ] = useState(0); 
    const [ showInvite, setShowInvite ] = useState(false); 
    const [ taskCount, setTaskCount ] = useState(0); 
    const [ tab, setTab] = useState("ailevel");
    const [ isNew, setIsNew] = useState(0);
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile() 
        // eslint-disable-next-line
    }, []); 
  
    
    const UserProfile = async e => {    
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {   
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
             
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', data.token);  
            localStorage.setItem('userdata', JSON.stringify(data));   
            localStorage.setItem('lang', data.lang);  
            localStorage.setItem('vip', data.vip);  
            localStorage.setItem('is_new', data.is_new);  
            localStorage.setItem('avatar', data.avatar);  
            setUsername(data.username);
            setBalance(data.balance);
            setInvitecode(data.invite_code);
            setVip(data.vip);
            setAvatar(data.avatar);
            

            let is_new = localStorage.getItem('is_new');  
            setIsNew(is_new);
            console.log("is_new")
            console.log(isNew)
            let user_name = localStorage.getItem('username');  
            setUsername(user_name);
            let avatar_url = localStorage.getItem('avatar');  
            setAvatar(avatar_url);
        } 
    }  


    return (
    <>
        <div className="page-wrapper task-wrapper">   
            <div className="module"> 
                <div className="box-account"> 
                    <div className="box-account-info"> 
                        <span className="avatar">
                            <img src={avatar} height={48}/>
                        </span>
                        <div className="box-account-info-title"> 
                            <h5>{t("Welcome Back!👋")}</h5>
                            <h4>{username}</h4>
                        </div>
                    </div>
                    <div className="box-account-info"> 
                        <img src={`images/vip${vip}.png`} height={48}/>
                    </div>
                </div>
            </div>  

            {isNew == 0 ? (
                <TaskOldMember/>
            ) : (
                <TaskNewMember/>
            )} 
        </div>
         
        <Footer/>
    </>
    );
};