
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import TextField from '@mui/material/TextField'; 

import { Profile, SetName } from '../../Services/User';  
import InnerNav from '../InnerNav';   
  
import LoadingSpinner from '../LoadingSpinner'; 
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconUser } from '../../assets/images/icon_user.svg'; 
import { ReactComponent as IconCoin } from '../../assets/images/icon_coin.svg'; 
import { ReactComponent as IconInfo } from '../../assets/images/icon_info.svg'; 
import { ReactComponent as IconEyeO } from '../../assets/images/icon_deye_o.svg'; 
import { ReactComponent as IconEyeC } from '../../assets/images/icon_deye_c.svg'; 

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap";
  

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Realname() {  
    const navigate = useNavigate();  
    const { t } = useTranslation();
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;  
            if(data.real_name != null && data.real_name != ''){ 
                setRealname(data.real_name); 
                setCanupdate(false); 
            }
            else{
                setCanupdate(true); 
            }
        } 
    } 
 
    const [canupdate, setCanupdate] = useState(false);    
    const [ realname, setRealname ] = useState("");   
  
    const handleSetName = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');  
        let real_name = realname;
        SetName({ 
            token,
            real_name
        }, CallbackSetName);
    } 
 
    const CallbackSetName = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data; 
            UserProfile();  
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
     
    useEffect(() => {
        setIsDisabled(realname === ''); 
    }, [ realname ]);

    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper account-wrapper">  
                <InnerNav path="security-settings" title={t("Set Real Name")}/>
               
                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleSetName} >   
                        <FormGroup className={`mb-3 ${!canupdate ? 'disabled' : ''}`}>
                            <label>{t("Real Name")}</label>
                            <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <IconUser/>
                            </InputGroupText> 
                            <Input 
                                placeholder={t("Real Name")}  
                                name="realname" 
                                value={realname}
                                onChange={(e)=>inputChangeHandler(setRealname, e)}   
                                disabled={!canupdate} 
                            /> 
                            </InputGroup>
                        </FormGroup>  

 
                        <div className="form-action btn-block mt-5">  
                            {canupdate ? (
                                <Button type="submit" color="primary" disabled={isDisabled}>{t("Submit")}</Button> 
                            ) : (
                                <Button color="primary" disabled>{t("Please contact admin for update")}</Button>  
                            )}
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};