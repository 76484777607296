
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
import { Profile, GetSupport, DepositHistory, WithdrawHistory } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';  
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';  
import { ReactComponent as IconEdit } from '../../assets/images/icon_edit.svg'; 
import { ReactComponent as IconBalance } from '../../assets/images/icon_balance.svg'; 
import { ReactComponent as IconInfo } from '../../assets/images/icon_info.svg'; 
import { ReactComponent as IconEyeO } from '../../assets/images/icon_eye_o.svg'; 
import { ReactComponent as IconEyeC } from '../../assets/images/icon_eye_c.svg'; 
import { ReactComponent as IconRecharge } from '../../assets/images/icon_recharge.svg'; 
import { ReactComponent as IconWithdraw } from '../../assets/images/icon_withdraw.svg'; 

import { ReactComponent as IconUser } from '../../assets/images/icon_user.svg'; 
import { ReactComponent as IconLanguage } from '../../assets/images/icon_language.svg'; 
import { ReactComponent as IconSecurity } from '../../assets/images/icon_security.svg'; 
import { ReactComponent as IconLevel } from '../../assets/images/icon_level.svg'; 
import { ReactComponent as IconHelp } from '../../assets/images/icon_help.svg'; 
import { ReactComponent as IconInvite } from '../../assets/images/icon_invite.svg'; 
 

import { useTranslation } from 'react-i18next';

import { 
    Button,
    Progress ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

export default function My() {  
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ supportUrl, setSupportUrl] = useState('');
    const [ username, setUsername ] = useState(""); 
    const [ balance, setBalance ] = useState(""); 
    const [ invitecode, setInvitecode ] = useState(""); 
    const [ vip, setVip ] = useState(0); 
    const [ avatar, setAvatar ] = useState("");  
    const [ progressTotal, setProgressTotal ] = useState("00"); 
    const [ progressBal, setProgressBal ] = useState("00"); 
    const [ toggleBalance, setToggleBalance] = useState(true); 
    const [ progressVal, setProgressVal] = useState(0); 
  
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }   
        UserProfile(); 
        // eslint-disable-next-line
    }, []); 

    const getMaskedBalance = () => {
      const maskedBalance = balance.toString().replace(/\d/g, '*');
      return `******`;
    };
  
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setUsername(data.username);
            setBalance(data.balance);
            setInvitecode(data.invite_code);
            setVip(data.vip);
            setAvatar(data.avatar);
            setProgressBal(data.progress_bal);
            setProgressTotal(data.progress_total);
            setProgressVal(data.progress_val);

            if(data.is_verify === 0){
                navigate('/verify');
            } 
        } 
    }
    const handleHelp = (e) => { 
        e.preventDefault();
        window.open(supportUrl, '_blank'); 
    };  
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleLogout = () => {
        setOpenConfirm(true);
    };
  
    const handleCloseReject = () => {
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false); 
        logout();
    };
    const logout = () => {
        localStorage.clear();
        navigate('/login');
    };
   
    const goto = (path) => { 
        navigate('/' + path);
    }; 

    return (
        <>
            <div className="page-wrapper my-wrapper">  
                
                <div className="module">
                    <div className="card-header">
                        <h4>{t("Personal Center")}</h4> 
                    </div>  
                </div> 

                <div className="module">
                    <div className="card">
                        <div className="card-body">
                            <div className="account-header-wrapper">
                                <div className="account-header">
                                    <span className="avatar">
                                        <img src={avatar} height={48}/>
                                    </span>
                                    <div className="account-title">
                                        <h4>{username}</h4> 
                                        <h5>{invitecode}</h5> 
                                    </div>
                                </div>
                                <span className="" onClick={() => goto("account")}><IconEdit/></span>
                            </div>
                            <div className="account-level">
                                <div className="account-level-header">
                                    <h5>{t("Level")} {vip}</h5>
                                    <p>{t("500 Points to next level")}</p>
                                </div>
                                <div className="account-level-info"> 
                                    <img src={`images/vip${vip}.png`} height={48}/>
                                </div>
                            </div>
                            <div className="account-level-bar">
                                <div className="account-level-progress">
                                    <Progress value={progressVal} />
                                </div>
                                <div className="account-level-progress-text">
                                    <span>{progressBal}/<span className="text-bold">{progressTotal}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="module account-balance">
                    <div className="card">
                        <div className="card-header">
                            <span>{t("Guarda Balance")} <IconInfo /></span>
                            <span className="btn-more"><IconBalance/></span> 
                        </div>
                        <div className="card-body">
                            <div className="balance-amount">
                                <span className="amount">$ {toggleBalance ? balance : getMaskedBalance()}</span>
                                { toggleBalance ? (
                                    <IconEyeC onClick={() => setToggleBalance(!toggleBalance)} height={18}/>
                                ) : (
                                    <IconEyeO onClick={() => setToggleBalance(!toggleBalance)} height={18}/>
                                )}
                            </div>
                            <div className="account-action">
                                <Button onClick={() => goto("deposit")} color="primary" outline><IconRecharge/><span>{t("Recharge")}</span></Button>
                                <Button onClick={() => goto("Withdrawal")} color="primary" outline><IconWithdraw/><span>{t("Withdraw")}</span></Button>
                            </div>
                        </div>
                    </div>
                </div>
                 
                <div className="module"> 
                    <div className="profile_nav"> 
                        <ul>
                            <li>
                                <span className="nav-link"  onClick={() => goto("account")}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><IconUser /></span>
                                        <span>{t("Personal information")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={() => goto("withdrawal-history")}>
                                    <span className="nav-title">
                                        <span className="nav-icon">
                                        <i className="fa-solid fa-money-check-dollar"></i>
                                        </span>
                                        <span>{t("Withdrawal History")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={() => goto("deposit-history")}>
                                    <span className="nav-title">
                                        <span className="nav-icon">
                                        <i className="fa-solid fa-coins"></i>
                                        </span>
                                        <span>{t("Deposit History")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li> 
                                <span className="nav-link"  onClick={() => goto("language")}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><IconLanguage /></span>
                                        <span>{t("Language")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li> 

                            <li>
                                <span className="nav-link"  onClick={() => goto("security-settings")}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><IconSecurity /></span>
                                        <span>{t("Security Settings")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>

                            <li> 
                                <span className="nav-link"   onClick={() => goto("About")}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-info"></i></span>
                                        <span>{t("About")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li> 
                            
                            <li> 
                                <span className="nav-link"   onClick={() => goto("q-n-a")}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-regular fa-circle-question"></i></span>
                                        <span>{t("Q&A")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li> 
                            <li>
                                <span className="nav-link"  onClick={() => goto("level-system")}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><IconLevel /></span>
                                        <span>{t("User Level")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            
                            <li>
                                <span className="nav-link" onClick={() => goto("q-n-a")}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><IconHelp /></span>
                                        <span>{t("Help and support")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>  
                            
                            <li>
                                <span className="nav-link" onClick={() => goto("invite")}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><IconInvite /></span>
                                        <span>{t("Invite Friend")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>  
                        </ul>
                    </div> 
                </div>


                <div className="module">
                    <div className="btn-block mt-5"> 
                        <Button color="primary" onClick={handleLogout}> 
                            <span>{t("Log Out")}</span>
                        </Button>
                    </div>
                </div>

            <Footer/>
            </div> 

            

            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            > 
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {t('Are you sure to exit??')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseReject}>{t('Cancel')}</Button>
                <Button onClick={handleCloseConfirm} autoFocus>
                {t('Confirm')}
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};