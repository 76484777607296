
import React, { useEffect } from 'react';
import './App.css'; 
import "notyf/notyf.min.css"; 
import '../../styles/main.scss';
import "../../assets/fontawesome/css/all.css";
 
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Home from '../Home/Home'; 
import Service from '../Home/Service'; 
import Shop from '../Home/Shop'; 
import Record from '../Home/Record'; 
import My from '../Home/My'; 

import Account from '../Home/Account'; 
import Bank from '../Home/Bank'; 
import PasswordWithdraw from '../Home/PasswordWithdraw'; 
import Password from '../Home/Password'; 
import Language from '../Home/Language'; 
import Realname from '../Home/Realname'; 

import RechargeForm from '../Home/RechargeForm'; 
import RechargeTron from '../Home/RechargeTron'; 
import RechargeType from '../Home/RechargeType'; 
import RechargeLogs from '../Home/RechargeLogs'; 
import Withdrawal from '../Home/Withdrawal'; 
import WithdrawalLogs from '../Home/WithdrawalLogs'; 
import TransactionLogs from '../Home/TransactionLogs'; 
import Invite from '../Home/Invite'; 
import Profile from '../Home/MyProfile'; 
import Vip from '../Home/Vip'; 
import TeamReport from '../Home/TeamReport'; 
import PrivacyPolicy from '../Home/PrivacyPolicy'; 
import TermsCondition from '../Home/TermsCondition'; 
import OrderInfo from '../Home/OrderInfo'; 
import QnA from '../Home/QnA'; 
import About from '../Home/About'; 
import Security from '../Home/Security'; 
import Level from '../Home/Level'; 
import Task from '../Home/Task'; 
import TaskDetail from '../Home/TaskDetail'; 
import TaskHistory from '../Home/TaskHistory'; 
 
import Login from '../Login/Login';
import Register from '../Login/Register'; 
import Landing from '../Login/Landing'; 
import Verify from '../Login/Verify'; 
import CompleteAccount from '../Login/CompleteAccount'; 

import PageNotFound from '../Layout/PageNotFound';   

import { useTranslation } from 'react-i18next';
import i18n from 'i18next'; // Import i18next
import { initReactI18next } from 'react-i18next';


import enTranslations from '../i18n/en.json';
import zhTranslations from '../i18n/zh.json';
import arTranslations from '../i18n/ar.json';

import ptTranslations from '../i18n/pt.json';
import esTranslations from '../i18n/es.json';
import frTranslations from '../i18n/fr.json';
import deTranslations from '../i18n/de.json';
import thTranslations from '../i18n/th.json';
import hiTranslations from '../i18n/hi.json';
import ruTranslations from '../i18n/ru.json';
import bnTranslations from '../i18n/bn.json';
import jaTranslations from '../i18n/ja.json';
import koTranslations from '../i18n/ko.json';
 
// Initialize i18next with translations
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      zh: { translation: zhTranslations },
      ar: { translation: arTranslations },

      pt: { translation: ptTranslations },
      es: { translation: esTranslations },
      fr: { translation: frTranslations },
      de: { translation: deTranslations },
      th: { translation: thTranslations },
      hi: { translation: hiTranslations },
      ru: { translation: ruTranslations },
      bn: { translation: bnTranslations },
      ja: { translation: jaTranslations },
      ko: { translation: koTranslations }
    },
    lng: localStorage.getItem('lang') || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
});

const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });
  
function App() { 
    // Initialize the language based on localStorage on app load
    useEffect(() => {
      const storedLang = localStorage.getItem('lang'); 
      if (storedLang) {
        i18n.changeLanguage(storedLang);
      }
    }, []);


    return(
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
            <div className="wrapper"> 
                <Router>
                    <Routes > 
                        <Route  path="*" element={<Navigate to="404" />} />
                        <Route  path="/*" element={<PageNotFound />} />
                        
                        <Route  exact path="/" element={<Landing/>}  /> 
                        
                        <Route  path="/login" element={<Login/>} />
                        <Route  path="/complete-account" element={<CompleteAccount/>} />
                        <Route  path="/register/:inviteCode?" element={<Register />} /> 
                        <Route  path="/404" element={<PageNotFound/>}  />
                        
                        <Route  path="/home" element={<Home/>}  />
                        <Route  path="/service" element={<Service/>}  />
                        <Route  path="/record" element={<Record/>}  />
                        <Route  path="/my" element={<My/>}  />

                        <Route  path="/account" element={<Account/>}  />
                        <Route  path="/bank" element={<Bank/>}  />
                        <Route  path="/password" element={<Password/>}  />
                        <Route  path="/withdraw-password" element={<PasswordWithdraw/>}  />
                        <Route  path="/language" element={<Language/>}  />
                        <Route  path="/realname" element={<Realname/>}  />

                        <Route  path="/withdrawal" element={<Withdrawal/>}  />
                        <Route  path="/withdrawal-history" element={<WithdrawalLogs/>}  />
                        
                        <Route  path="/deposit-type" element={<RechargeType/>}  />
                        <Route  path="/deposit-tron" element={<RechargeTron/>}  />
                        <Route  path="/deposit" element={<RechargeForm/>}  />
                        <Route  path="/deposits/:type" element={<RechargeForm/>}  />
                        <Route  path="/deposit-history" element={<RechargeLogs/>}  />

                        <Route  path="/transaction-logs" element={<TransactionLogs/>}  />
                        <Route  path="/team-report" element={<TeamReport/>}  />
                        <Route  path="/invite" element={<Invite/>}  />
                        <Route  path="/profile" element={<Profile/>}  />

                        <Route  path="/shop/:tier?" element={<Shop/>}  />
                        <Route  path="/tasks/:tier?" element={<Shop/>}  />
                        <Route  path="/packages" element={<Vip/>}  />
                        <Route  path="/order/:orderNumber?" element={<OrderInfo/>}  />

                        <Route  path="/privacy-policy" element={<PrivacyPolicy/>}  />
                        <Route  path="/terms-conditions" element={<TermsCondition/>}  />
                        <Route  path="/q-n-a" element={<QnA/>}  />
                        <Route  path="/about" element={<About/>}  />
                        <Route  path="/landing" element={<Landing/>}  />
                        <Route  path="/verify" element={<Verify/>}  />
                        <Route  path="/security-settings" element={<Security/>}  />
                        <Route  path="/level-system" element={<Level/>}  />
                        
                        <Route  path="/task-center" element={<Task/>}  />
                        <Route  path="/task-detail" element={<TaskDetail/>}  />
                        <Route  path="/task-history/:type?" element={<TaskHistory/>}  />
                        <Route  path="/record-center" element={<Task/>}  />
                    </Routes >
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;