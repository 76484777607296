
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
import '../Common.css'; 
import { Profile, Pages, Banner, PageVersion, BannerVersion } from '../../Services/User';  
import useToken from '../App/useToken'; 
import { useTranslation } from 'react-i18next'; 
import { Divider } from 'react-vant';

import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";

import { Tabs , Popup, Cell } from 'react-vant'
   
export default function TaskCompare() {  
 
    const { t } = useTranslation(); 
    
    return (
    <> 
        <div className="task-center">
            <div className="module">
                <div className="box-card mt-3">
                    <h4>{t("AI Levels Comparison")}</h4>
                    <table className="table-compare">
                        <thead>
                            <tr>
                                <th>{t("Benifits")}</th>
                                <th>{t("Level 1")}</th>
                                <th>{t("Level 2")}</th>
                                <th>{t("Level 3")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{t("No more manual effort!")}</th>
                                <td><img src={"images/icon_tick.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                            </tr>
                            <tr>
                                <th>{t("Access Exclusive Tasks")}</th>
                                <td><img src={"images/icon_cross.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                            </tr>
                            <tr>
                                <th>{t("Maximise Efficiency")}</th>
                                <td><img src={"images/icon_cross.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                            </tr>
                            <tr>
                                <th>{t("Increased Earning")}</th>
                                <td><img src={"images/icon_cross.png"} /></td>
                                <td><img src={"images/icon_cross.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                            </tr>
                            <tr>
                                <th>{t("Personalised Tasks")}</th>
                                <td><img src={"images/icon_cross.png"} /></td>
                                <td><img src={"images/icon_cross.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                            </tr>
                            <tr>
                                <th>{t("Time Saving")}</th>
                                <td><img src={"images/icon_cross.png"} /></td>
                                <td><img src={"images/icon_cross.png"} /></td>
                                <td><img src={"images/icon_tick.png"} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>  
            </div> 
        </div>   
    </>
    );
};