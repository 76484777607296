
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import TextField from '@mui/material/TextField'; 
import InputAdornment from '@mui/material/InputAdornment';
import QRCode from 'qrcode.react';
import { Tabs , Popup, Cell, List } from 'react-vant'

import { Profile, generateInviteCode, getInviteCode } from '../../Services/User';  
  
import ImageCache from '../ImageCache';
import { useTranslation } from 'react-i18next'; 
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Invite() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();   
    const [ inviteCode, setInviteCode ] = useState("");  
    const [ inviteUrl, setInviteUrl ] = useState("");  
    const [ finished, setFinished] = useState(false)
    const [ currentPage, setCurrentPage] = useState(0); 
    const [ lastPage, setLastPage] = useState(0); 
    const [ codelists, setCodelists] = useState([]); 
    const [loading, setLoading] = React.useState(false)   
    const [isDisabledLink, setIsDisabledLink] = useState(false);   
    const [ isLoading, setIsLoading] = useState(false)
    
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        GetCodeLists();   
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const GetCodeLists = async e => {    
        
        if(isLoading){ 
            return
        } 
        if(currentPage > 0 && currentPage === lastPage){
            return
        }
        setIsLoading(true); 

        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            if(currentPage > 0 && currentPage === lastPage){
                return
            }
            let page = currentPage + 1; 
            getInviteCode({
                token,
                page
            }, CallbackInviteCode);  
        }
    } 
    
    const CallbackInviteCode = (returnData) => {  
        setIsLoading(false);
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setCodelists(data.data); 
            setCurrentPage(data.current_page) 
            setLastPage(data.last_page)  
            if(data.current_page === data.last_page){
                setFinished(true)
            }

        } 
    } 
  
    const copyToClipboard  = (code) => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        
        let url = "https://shopbacktest.hellowrd.com/register/"+code
                    
        const modifiedUrl = replaceDomain(url); 
        textArea.value = modifiedUrl;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        notyf.success(t("Invite url have copy to clipboard"));   
    }; 

    function replaceDomain(apiurl) {
        const browserUrl = window.location.href;
 
        const apiDomainMatch = apiurl.match(/^(https?:\/\/)([^/]+)/);
        const apiDomain = apiDomainMatch ? apiDomainMatch[2] : null;
        
        // Parse the domain from the browser URL
        const browserDomainMatch = browserUrl.match(/^(https?:\/\/)([^/]+)/);
        const browserDomain = browserDomainMatch ? browserDomainMatch[2] : null;
        
        // Replace the domain in the API URL with the domain from the browser URL
        if (apiDomain && browserDomain) {
            return apiurl.replace(apiDomain, browserDomain);
        }
        
        // Return the original URL if domains couldn't be parsed
        return apiurl;
    }
     
    const handleGenerateCode = async e => { 
        let token = localStorage.getItem('token');  
        generateInviteCode({
            token
        }, CallbackHandleGenerateCode);
    } 
 
    const CallbackHandleGenerateCode = (returnData) => {    
        setLoading(false);
        GetCodeLists();
        var code = returnData.code; 
        if(code === 1)
        {  
            setIsDisabledLink(true);
            notyf.success(returnData.data.result); 
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }


  return (
    <>
        <div className="page-wrapper invite-wrapper"> 
            <div className="inner-top-nav">
                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                <span>{t('Invite Friend')}</span>
                <span></span>
            </div> 
            <div className="module">  
                {/* <div className="nav-href">  
                    {t("Not enough invite code?")} <span className={`nav-link ${isDisabledLink ? 'disable' : ''}`} onClick={isDisabledLink ? null : handleGenerateCode}>{t("Generate New Code")}</span> 
                </div> */}

                <div className="code-lists mt-5"> 
                {codelists.length > 0 ? (
                    <List finished={finished} onLoad={GetCodeLists} offset={0} loadingText={t("loading...")}>
                        {codelists.map(item => (
                            <div className="box-card mt-3" key={item.id}>
                                <div>{t("code")}: {item.code}</div>
                                <div>{t("downline")}: {item.downline}</div>
                                <div>{t("status")}: {item.status}</div>
                                <span className="btn-copy" onClick={() => copyToClipboard(item.code)} >{t('copy')}</span>
                            </div> 
                        ))}
                    </List>
                ) : (
                    <div className="empty">{t("no code available")}</div>
                )} 
                </div>  
            </div>
  
        </div> 
    </>
  );
};