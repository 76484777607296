import React, { useEffect, useState } from 'react';

const ImageCache = ({ src, alt, width, height, title, className }) => {
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const cachedImage = localStorage.getItem(src);

    if (cachedImage) {
      setImgSrc(cachedImage);
    } else {
      const img = new Image();
      img.src = src;

      img.onload = () => {
        localStorage.setItem(src, src);
        setImgSrc(src);
      };
    }
  }, [src]);

  return (
    <img
      src={imgSrc}
      alt={alt}
      width={width}
      height={height}
      title={title}
      className={className}
    />
  );
};

export default ImageCache;
